import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function FRPSwimmingPoolManufactureInIndia(){
    const imgData = [
        {
          img1: "Product-Images/Water-slides-manufactures-in-india.jpg",
          name: "Swimming Pool Curve Open Slide",
          price: "Rs 31 Lakh / Piece",
          d1: "Material",
          value1: "FRP",
          d2: "Usage/Application",
          value2: "Water Park",
          d3: "Color",
          value3: "All Colour Available",
          d4: "Age Group",
          value4: "Above 14 Year",
          d5: "Funnel Diameter",
          value5: "32 inch",
          d6: "Customisation",
          value6: "Customised",
          d7: "Country of Origin",
          value7: "Made in India",
          para: "Nageswar Fiber and Fabrication: These slides are a must for all Water Parks, Hotels, and Resorts. They are manufactured as per International Standards using high-grade basic materials procured from reliable vendors. These water slides are offered in a variety of designs, shapes, sizes, and colours.",
        },
        {
          img1: "Product-Images/Fiberglass-swimming-pool-contractor-in-pune.jpg",
          name: "Frp Portable Swimming Pool",
          price: "Rs 12 Lakh / Piece",
          d1: "Minimum Order Quantity",
          value1: "1 Piece",
          d2: "Height",
          value2: "4 Ft",
          d3: "Usage/Application",
          value3: "Residential",
          d4: "Material",
          value4: "FRP/Fiberglass",
          d5: "Pool Type",
          value5: "Outdoor",
          d6: "Color",
          value6: "Blue",
          d7: "Capacity",
          value7: "Approx 16000 ltr",
          d8: "Filter Pump",
          value8: "Included",
          d9: "Design Type",
          value9: "Rectangular",
          d10: "Features",
          value10: "ISO NPG Gel Coat Finish",
          d11: "Installation",
          value11: "Included",
          d12: "Power",
          value12: "Single Phase",
          d13: "Total Jet",
          value13: "Turbo Jet",
          d14: "Country of Origin",
          value14: "Made in India",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins, and much more. Our whole product assortment is designed and manufactured by experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
          para1: "5 mm thickness fiber material used and heavy fabrication.",
        },
        {
          img1: "Product-Images/Fiberglass-swimming-pool-manufactures-near-me.jpg",
          name: "FRP Swimming Pool",
          price: "Rs 2.35 Lakh / Piece",
          d1: "Height",
          value1: "4 Ft",
          d2: "Usage/Application",
          value2: "Hotels/Resorts",
          d3: "Material",
          value3: "FRP",
          d4: "Pool Type",
          value4: "Outdoor",
          d5: "Color",
          value5: "Blue",
          d6: "Country of Origin",
          value6: "Made in India",
          para: "Nageswar Fiber and Fabrication: These pools are essential for all Water Parks, Hotels, and Resorts. They are manufactured as per International Standards using high-grade basic materials procured from reliable vendors. These pools are offered in a variety of designs, shapes, sizes, and colours.",
        },
        {
          img1: "Product-Images/Water-park-slides-manufactures-in-pune.jpg",
          name: "FRP Spherical Water Slide",
          price: "Rs 4,000 / Feet",
          d1: "Number Of Slides",
          value1: "1 Slide",
          d2: "Material",
          value2: "Glass Fibre",
          d3: "Exit",
          value3: "Pool",
          d4: "Color",
          value4: "All Colour Available",
          d5: "Rider Capacity",
          value5: "120 Per hour",
          d6: "No Of Riders",
          value6: "1",
          d7: "Usage/Application",
          value7: "Water Park, Resort, Swimming Pool",
          d8: "Country of Origin",
          value8: "Made in India",
          para: "Nageswar Fiber and Fabrication: These slides are a must for all Water Parks, Hotels, and Resorts. They are manufactured as per International Standards using high-grade basic materials procured from reliable vendors. These water slides are offered in a variety of designs, shapes, sizes, and colours.",
        },
       
        {
          img1: "Product-Images/Swiming-pool-Flteration-plant-manufactures-in-pune.jpg",
          name: "Swimming Pools",
          price: "Rs 600 / sq ft",
          d1: "Usage/Application",
          value1: "Hotels/Resorts",
          d2: "Material",
          value2: "FRP",
          d3: "Pool Type",
          value3: "Outdoor",
          d4: "Color",
          value4: "Blue",
          d5: "Capacity",
          value5: "7/8 Person",
          para: "Nageswar Fiber and Fabrication: These slides are a must for all Water Parks, Hotels, and Resorts. They are manufactured as per International Standards using high-grade basic materials procured from reliable vendors. These water slides are offered in a variety of designs, shapes, sizes, and colours.",
        },
      ];
    return(
        <>
 <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries|Tube Slide Water Park Contractor in Pune | Fiber Pillar
          Manufacturers in Pune | Water-Fountain-Manufacturers-in-Pune | frp
          Dome Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Pillar Manufacturers in Pune,Water-Fountain-Manufacturers-in-Pune,frp Dome Manufacturers in India,Plastic dustbin manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
       <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Frp Swimming Pool</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li> FRP Swimming Pool Manufacturers in India</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
           {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}
        <div className="container product-section">
          <h1>
          FRP Swimming Pool Manufacturers in India – Nageshwar Fibre And
            Fabrication Industries
          </h1>
        <p>Nageshwar Fibre And Fabrication Industries is a well-known name in the industry, offering high-quality FRP (Fibre Reinforced Plastic) swimming pools across India. With a focus on durability, ease of installation, and aesthetic appeal, our pools are ideal for residential, commercial, and recreational applications such as homes, resorts, hotels, and water parks.</p>
        </div>
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
      {/*  ================= Contact Details ==================== */}
      <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        </>
    )
}