import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import React from "react";
import { Link } from "react-router-dom";
import "./App.css";
import { Helmet } from "react-helmet";

export default function Footer() {
  return (
    <>
     <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar Fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Nageshwar Fibre And Fabrication Industries| Contact: +91 8793191191,
          +91 9762431883|Fibre Pillar Manufacturers in Pune |
          Water-Fountain-Manufacturers-in-Pune | frp Dome Manufacturers in India
          | Plastic dustbin manufacturers in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar Fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fibre Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fibre Pillar Manufacturers in Pune,Water-Fountain-Manufacturers-in-Pune,frp Dome Manufacturers in India,Plastic dustbin manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/images/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            {/* Logo Section */}
            <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="footer-title">
                <h4>Products</h4>
              </div>
              <ul className="links">
              <li><Link to="/Playground-Slide-Manufacturers-in-Pune">Playground Slide Manufacturers in Pune</Link></li>
                                    <li><Link to="/Water-Park-Slide-Manufacturers-in-India">Water Park Slide Manufacturers in India</Link></li>
                                    <li><Link to="/Park-Slides-Manufacturer-in-Pune">Park Slides Manufacturer in Pune</Link></li>
                                    <li><Link to="/Water-Slides-Manufacturers-in-India" > Water Slides Manufacturers in India</Link></li>
                                  <li><Link to="/Wedding-Horse-Rath-Manufacturer-from-Pune" > Wedding Horse Rath Manufacturer from Pune</Link></li>
                                   <li><Link to="/FRP-Swimming-Pool-Manufacturers-in-India" > FRP Swimming Pool Manufacturers in India</Link></li>
                                  <li><Link to="/Water-Park-Equipment-Manufacturers-in-India" >Water Park Equipment Manufacturers in India</Link></li>
                                  <li><Link to="/FRP-Boat-Manufacturers-in-India">FRP Boat Manufacturers in India</Link></li>
                                   <li><Link to="/Fiber-Stage-manufacturer-in-Pune">Fibre Stage manufacturer in Pune</Link></li> 
                                    <li><Link to="/Rain-Dance-Setup-Manufacturers-in-Pune" > Rain Dance Setup Manufacturers in Pune</Link></li> 
                                    <li><Link to="/Playground-Equipment-Manufacturers-in-India" >Playground Equipment Manufacturers in India</Link></li> 
                                    <li><Link to="/Animal-and-fiber-statue-manufacturers-in-pune"> Animal and Fibre Statue Manufacturers in Pune</Link></li>
                                    <li><Link to="/Outdoor-Playground-Equipment-Manufacturers-in-India" >Outdoor Playground Equipment Manufacturers in India</Link></li>
                                    <li><Link to="/Family-Rides-at-Thorpe-Park-Manufacturers-in-India">Family Rides at Thorpe Park Manufacturers in India</Link></li>
                                    <li><Link to="/Fabricated-Gate-Manufacturers-in-Pune">Fabricated Gate Manufacturers in Pune</Link></li>
              </ul>
             
            </div>

            {/* Service Links Section */}
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="footer-title">
                <h4>Products</h4>
              </div>
              <ul className="links">
               
                <li><Link to="/Umbrella-Manufacturers-in-Pune" >Umbrella Manufacturers in Pune</Link></li>
                                    <li><Link to="/Fiberglass-Swimming-Pool-Contractors-in-Pune" >Fibreglass Swimming Pool Contractors in Pune</Link></li>
                                    <li><Link to="/Tube-Slide-Water-Park-Contractor-in-Pune" >Tube Slide Water Park Contractor in Pune</Link></li>
                                    <li><Link to="/Fiber-Pillar-Manufacturers-in-Pune">Fibre Pillar Manufacturers in Pune</Link></li>
                                  <li><Link to="/Water-Fountain-Manufacturers-in-Pune" >Water-Fountain-Manufacturers-in-Pune</Link></li>
                                   <li><Link to="/frp-Dome-Manufacturers-in-India" >frp Dome Manufacturers in India</Link></li>
                                   <li><Link to="/Plastic-dustbin-manufacturers-in-Pune" >Plastic dustbin manufacturers in Pune</Link></li>
                                    <li><Link to="/Portable-Cabins-Manufacturers-in-Pune" >Portable Cabin Manufacturers in Pune</Link></li>
                                      <li><Link to="/Fiber-Door-Manufacturers-in-Pune" >Fibre Door Manufacturers in Pune</Link></li>
                                   <li><Link to="/frp-Planters-Manufacturers-in-Pune">FRP Planters Manufacturers in Pune</Link></li>
                                   <li><Link to="/Outdoor-Swing-Manufacturers-in-Pune" >Outdoor Swing Manufacturers in Pune</Link></li>
                                   <li><Link to="/Wedding-Doli-Manufacturer-in-Pune">Wedding Doli Manufacturer in Pune</Link></li>
                                   <li><Link to="/Shivaji-Maharaj-Statue-Maker-in-Pune">Shivaji Maharaj Statue Maker in Pune</Link></li>
                                   <li><Link to="/Trackless-Train-Manufacturers-in-Pune">Trackless Train Manufacturers in Pune</Link></li>
                                   <li><Link to="/frp-Jeep-Rath-Manufacturers-in-Pune">frp Jeep Rath Manufacturers in Pune</Link></li>
                                   <li><Link to="/frp-Portable-Toilets-and-Urinals-Manufacturers-in-Pune">frp Portable Toilets and Urinals Manufacturers in Pune
                                   </Link></li>

                                  
              </ul>
            </div>

            {/* Product Links Section */}
            <div className="col-sm-6 col-md-6 col-lg-2">
              <div className="footer-title">
                <h4>Products</h4>
              </div>
                      <ul className="links">
                                   
                                    
                                   <li><Link to="/Fiber-Bathroom-Door-Manufacturers-in-Pune">Fibre Bathroom Door Manufacturers in Pune</Link></li>
                                   <li><Link to="/Fibre-School-Furniture-Manufacturers-in-Pune">Fibre School Furniture Manufacturers in Pune</Link></li>
                                   <li><Link to="/Garbage-Truck-Manufacturers-in-Pune">Garbage Truck Manufacturers in Pune</Link></li>
                                   <li><Link to="/Designer-Wall-Mural-Manufacturers-in-Pune">Designer Wall Mural Manufacturers in Pune</Link></li>
                                   <li><Link to="/frp-Mobile-Toilet-Manufacturer-in-Pune">frp Mobile Toilet Manufacturer in Pune</Link></li>
                                   <li><Link to="/frp-Temple-Chariot-Manufacturers-in-Pune">frp Temple Chariot Manufacturers in Pune</Link></li>
                                   <li><Link to="/Sports-Trampoline-Manufacturers-in-India">Sports Trampoline Manufacturers in India</Link></li>
                                   <li><Link to="/frp-Transmitter-Canopy-Manufacturers-in-Pune">frp Transmitter Canopy Manufacturers in Pune</Link></li>
                                   <li><Link to="/Fabrication-Companies-in-Pune"> Fabrication Companies in Pune</Link></li>
                                   <li><Link to="/Food-van-Manufacturers-in-Pune">Food van Manufacturers in Pune</Link></li>
                                   
                                  
                        </ul>
                        



            </div>

            {/* Newsletter Section */}
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="newsletter">
                <h5>Contact Details</h5>
                {/* <img
                  className="" style={{height:"100px"}}
                  src="logo.jfif"
                  alt="Footer Logo"
                /> */}


                 <img src="logo.jpg" alt="" className="" style={{height:"100px"}}/>
                <ul className="" >
                  <li className="pt-2">
                    <p>
                      Phone Number <br />
                      <a href="tel:+91 8793191191" className="text-white">+91 8793191191</a>
                      <br />
                      <a href="tel:+91 9762431883" className="text-white">91 9762431883</a>
                    </p>
                    <p>
                      Email Id <br />
                      <a href="mailto:info@nageshwarfiber.com" className="text-white">
                        info@nageshwarfiber.com
                      </a>
                    </p>
                    <p>
                      Address
                      <address>
                        {" "}
                        Nageshwar Fibre And Fabrication Industries Gat No. 493,
                        Shikrapur, Near Pune Nagar Highway, Malthan Phata,
                        Shirur, Pune - 412105, Maharashtra, India
                      </address>
                    </p>
                    <div
                  style={{ width: "100%", height: "200px" }}
                  className="map-box"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3779.1811796199286!2d74.14376007519606!3d18.700704982428153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQyJzAyLjUiTiA3NMKwMDgnNDYuOCJF!5e0!3m2!1sen!2sin!4v1728016394392!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Embed"
                  ></iframe>
                </div>
                    
                  </li>
                </ul>
              </div>

              
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="copyright">
          <div className="container">
            <p>
               All rights reserved Nageshwar Fibre & Fibrication Industries 2024 Developed By
               <Link to="https://dexterintelligence.com/" target="_blank"> Dexter Intelligence </Link> |
               <Link to="https://www.urvahosting.com/" target="_blank"> Hosted By Urva Hosting </Link>
            </p>
           
          </div>
        </div>

        {/* <!-- =============== whatsapp and phone button =============== --> */}
        <div class="phone-float">
          <a href="tel:+918793191191">
            <i class="bi bi-telephone-fill"></i>
            <span>
              Call Us
              <br />
              <small>+91 8793191191</small>
            </span>
          </a>
        </div>

        <div class="whats-float">
          <a
            href="https://wa.me/+918793191191?text=Welcome to Nageshwar Fibre & Fabrication Industries. We specialize in high-quality fiber and fabrication products that cater to a wide range of industries. How can we assist you today?"
            target="_blank"
          >
            <span>
              WhatsApp
              <br />
              <small style={{ textAlign: "right" }}>+91 8793191191</small>
            </span>
            <i class="bi bi-whatsapp"></i>
          </a>
        </div>
        {/* ========================= */}
      </footer>
    </>
  );
}
