import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    industry: "0",
    message: "",
  });


  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "c730eb9f-95e0-4f01-908a-abeb40b305df");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
    }
  };

  const showAlert = () => {
    Swal.fire({
      title: 'Success!',
      text: 'You clicked the button.',
      icon: 'success',
      confirmButtonText: 'OK'
    });
  };


  return (
    <>
    <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Nageshwar Fibre And Fabrication Industries| Contact: +91 8793191191, +91 9762431883|Animal and Fiber Statue Manufacturers in Pune
          | Outdoor Playground Equipment Manufacturers in India | Family Rides at Thorpe Park Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Animal and Fiber Statue Manufacturers in Pune,Outdoor Playground Equipment Manufacturers in India
,Family Rides at Thorpe Park Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section
        className="breadcrumb-header gallery-image"
        id="page"
       
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Contact Us</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ================== Contact form ==================== */}

      <section className="contact-section">
        <div class="container">
          <div className="quote">
            <div className="container">
              <div className="quote-box">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="quote-info d-flex align-items-start flex-column">
                      <div className="info-top mb-auto">
                        <div className="item  text-center p-2">
                        <i class="bi bi-telephone-fill"></i><br/>
                          <h5>Contact Number</h5>
                          <div>
                          <a href="tel:+918793191191" className="text-white">
                            +91 8793191191
                            </a><br/>
                            <a href="tel:+919762431883" className="text-white">
                              +91 9762431883
                            </a>
                          </div>

                          </div>

                            <div className="text-center p-2 item">
                            <i class="bi bi-envelope"></i><br/>
                              <h5>Email</h5>
                              <div>
                                <a href="mailto:info@nageshwarfiber.com" className="text-white">
                                  info@nageshwarfiber.com
                                </a>
                              </div>
                            </div>

                            <div className="item p-2 address-box text-center">
                            <i class="bi bi-house"></i><br/>
                              <h5>Address</h5>
                              <div>
                             
                              <p className="address-text">
                                Nageshwar Fibre And Fabrication Industries Gat
                                No. 493, Shikrapur, Near Pune Nagar Highway,
                                Malthan Phata, Shirur, Pune - 412105,
                                Maharashtra, India
                              </p>
                              <div style={{ width: "100%", height: "225px" }} className="map-box">
                                <iframe
                                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3779.1811796199286!2d74.14376007519606!3d18.700704982428153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQyJzAyLjUiTiA3NMKwMDgnNDYuOCJF!5e0!3m2!1sen!2sin!4v1728016394392!5m2!1sen!2sin"
                                  width="100%"
                                  height="250"
                                  style={{ border: 0 }}
                                  allowFullScreen=""
                                  loading="lazy"
                                  referrerPolicy="no-referrer-when-downgrade"
                                  title="Google Maps Embed"
                                ></iframe>
                              </div>
                              </div>
                             
                            </div>
                   
                          {/* ================ Email ============ */}
                       
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="text-box">
                      <div className="sec-title">
                        <h3>Contact Us</h3>
                      </div>
                      <form  onSubmit={onSubmit}>
                        <div className="row">
                          <div className="col-md-6">                            <div className="quote-item">
                              <input
                                type="text"
                                name="name"
                                placeholder="Name"
                              
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="quote-item">
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                               
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="quote-item">
                              <input
                                type="text"
                                name="subject"
                                placeholder="Enter Your Subject"
                              
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="quote-item select-list  pt-2 pb-2 mb-3">
                              <select
                                name="industry"
                               
                                className="myinputbox"
                              >
                                <option value="0">Select your Product</option>
                                <option value="1">Animals</option>
                                <option value="2">Benches</option>
                                <option value="3">Cabins</option>
                                <option value="4">Domes </option>
                                <option value="5">Dustbin </option>
                                <option value="6">Fountain</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="quote-item">
                              <textarea
                                name="message"
                                placeholder="Leave Your Message Here....."
                               
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="quote-item">
                              <button type="submit" className="btn-1 btn-3"  onClick={showAlert}>
                                Submit 
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
