import { Link } from "react-router-dom";
import "./App.css";
import "./Policy.css";

export default function Term() {
  return (
    <>
      <section
        className="breadcrumb-header breadcrumb-header-2 gallery-image"
        id="page"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Terms & Condition</h1>
                <ul>
                  {/* Use Link component if routing is set up */}
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Terms & Condition</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ */}
      <section className="container">
        <div class="row">
          <div class="col-12 col-md-8">
            <div className="privacy-policy-container">
              <div className="mb-4">
                <img
                  src="products-img/Termes and Condition.jpg"
                  alt="policy img"
                />
              </div>
              <p>Welcome to Nageshwar Fibre and Fabrication!</p>

              <p>
                These terms and conditions outline the rules and regulations for
                the use of Nageshwar Fibre and Fabrication's Website, located at
                www.nageshwarfiber.com.
              </p>

              <h2>1. Acceptance of Terms</h2>
              <p>
                By accessing this website, we assume you accept these terms and
                conditions. Do not continue to use Nageshwar Fibre and
                Fabrication if you do not agree to take all of the terms and
                conditions stated on this page.
              </p>

              <h2>2. Use of the Site</h2>
              <p>
                You agree to use our website only for lawful purposes and in a
                way that does not infringe the rights of, restrict, or inhibit
                anyone else's use and enjoyment of the site.
              </p>

              <h2>3. Intellectual Property Rights</h2>
              <p>
                Unless otherwise stated, Nageshwar Fibre and Fabrication and/or
                its licensors own the intellectual property rights for all
                material on this website. All intellectual property rights are
                reserved.
              </p>

              <h2>4. User-Generated Content</h2>
              <p>
                Parts of this website offer users the opportunity to post and
                exchange opinions and information in certain areas of the
                website. Nageshwar Fibre and Fabrication does not filter, edit,
                publish or review comments prior to their presence on the
                website.
              </p>

              <h2>5. Limitation of Liability</h2>
              <p>
                In no event shall Nageshwar Fibre and Fabrication, nor any of
                its officers, directors, and employees, be held liable for
                anything arising out of or in any way connected with your use of
                this website, whether such liability is under contract, tort, or
                otherwise.
              </p>

              <h2>6. Termination</h2>
              <p>
                We may terminate or suspend access to our site immediately,
                without prior notice or liability, for any reason, including
                breach of the Terms.
              </p>

              <h2>7. Governing Law</h2>
              <p>
                These terms and conditions are governed by and construed in
                accordance with the laws of India, and you submit to the
                non-exclusive jurisdiction of the courts located in Pune,
                Maharashtra, for the resolution of any disputes.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 ">
            <div className="">
              <div className="contact-box">
                <h3>Contact Number</h3>
                <p>
                  <a href="tel:+91 8793191191">+91 8793191191</a>
                </p>
                <p>
                  <a href="tel:+91 9762431883">+91 9762431883</a>
                </p>
              </div>
              <div className="contact-box">
                <h3>Address</h3>
                <p className="">Nageshwar Fibre And Fabrication Industries</p>
                <p>
                  Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan
                  Phata, Shirur, Pune - 412105, Maharashtra, India
                </p>
              </div>
              <div className="contact-box">
                <h3>Email</h3>
                <p>info@nageshwarfiber.com</p>
              </div>

              <div className="contact-box">
                <div
                  style={{ width: "100%", height: "200px" }}
                  className="map-box"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3779.1811796199286!2d74.14376007519606!3d18.700704982428153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQyJzAyLjUiTiA3NMKwMDgnNDYuOCJF!5e0!3m2!1sen!2sin!4v1728016394392!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
