import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import './Slider.css'
import { motion } from 'framer-motion';
import "./ProductBased.css"
import { Helmet } from "react-helmet";

export default function PlaygroundSlidesManufacturesInPune(){
  const imgData = [
    {
        img1:'Product-Images/Playground-slides-manufactures-in-pune.jpg',
        name:'FRP Playground Slides',
        price:' Rs 60,000  / Piece Get Best Price',
        d1:'Material',
        value1:'FRP',
        d2:'Color',
        value2:'Yellow',
        d3:'Slide Type',
        value3:'Straight',
        d4:'Location Type',
        value4:'Outdoor',
        d5:'Rust Resistance',
        value5:'Yes',
        d6:'Country of Origin',
        value6:'Made in India',
        para:'Backed by a rich industrial experience, we have come up with a world class assortment of FRP Playground Slides. Features:DurableGood quality Excellent finish Superb design Full Set',
    },


   
    {
        img1:'Product-Images/Playground-equipment-manufactures-in-india.jpg',
        name:' Kids Playground Equipment',
        price:'Rs 1.25 Lakh  / Piece Get Best Price',
        d1:'Minimum Order Quantity',
        value1:'1 Set',
        d2:'Material',
        value2:'FRP',
        d3:'Brand',
        value3:'NFF BRAND',
        d4:'Size',
        value4:'25*25*14 Ft',
        d5:'Color',
        value5:'All colors',
        d6:'Usage/Application',
        value6:'Playgrond kids',
        d7:'Capacity',
        value7:'10/15 Chldren',
        para:'NFF Industries,are the best children play park Equipment manufacturers in PUNE. we engaged in offerng   wide range of children1 play park equipment. These are manufactured from high grad quality materiail.very safe for kids. ',
    },

    {
        img1:'products-img/Park-Slides-manufacturerin-pune.jpg',
        name:'Frp Curve Slide',
        price:'Rs 90,000  / Piece Get Best Price',
        d1:'Minimum Order Quantity',
        value1:'1 Set',
        d2:'Material',
        value2:'FRP',
        d3:'Slide Type',
        value3:'Curve Slide',
        d4:'Number Of Slides',
        value4:'2 Slides',
        d5:'Exit',
        value5:'Pool',
        d6:'Color',
        value6:'All Colour Available',
        d7:'No Of Riders',
        value7:'2',
        d8:'Features',
        value8:'Durable ,Crack Proof',
        d9:'Usage/Application',
        value9:'Play Ground, Park',
        d10:'Location Type',
        value10:'Outdoor',
        d11:'Country of Origin',
        value11:'Made in India',
        para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.',
    },
    
    {
      img1:'Product-Images/Water-park-equipment-manufactures-in-pune.jpg',
      name:'FRP Outdoor Sperical Slide',
      price:'Rs 75,000  / Set Get Best Price',
      d1:'Minimum Order Quantity',
      value1:' 5 Set',
      d2:'Material',
      value2:'FRP',
      d3:' Slide Type',
      value3:'Spiral Slide',
      d4:' Color',
      value4:'Multicolour',
      d5:' Age Group',
      value5:'3-12 Years',
      d6:'Usage/Application',
      value6:'Outdoor',
      d8:'Brand',
      value8:'NFF INDUSTRIES',
      d9:'Location',
      value9:'Type	Outdoor',
      d10:'Country of Origin',
      value10:'Made in India',
      para:' Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.',
      para1:' Packaging Details: Standard Package Wrapped by Cotton And Wrapped Paper'
    },


    {
      img1:'Product-Images/FRP Airplane Slide.jpg',
      name:'FRP Airplane Slide',
      price:'Rs 3.5 Lakh  / Piece',
      d1:'Minimum Order Quantity',
      value1:'1 Piece',
      d2:'Material',
      value2:'FRP',
      d3:'Brand',
      value3:'Kidsplay',
      d4:'Color',
      value4:'All Color',
      d5:'Location',
      value5:'	Outdoor',
      d6:'Country of Origin',
      value6:'Made in India',
      d7:'Slide Type',
      value7:'Straight',
      para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.',
      para1:'Frp airoplane capacity upto 30 kids, Upto 200 kgs capacity'
    },
    {
      img1:'products-img/FRP-Playground-Slides-Manufactures-in-pune.jpg',
      name:'Playground Slides',
      price:'Rs 90,000  / Piece',
      d1:'Minimum Order Quantity',
      value1:'1 Piece',
      d2:'Material',
      value2:'Fibreglass',
      d3:'Brand',
      value3:'Kidsplay',
      d4:'Color',
      value4:'Red',
      d5:'Slide Type',
      value5:'Roller',
      d6:'Location Type',
      value6:'Outdoor',
      d7:'Country of Origin',
      value7:'Made in Indiat',
      para:'With honest intentions to improve the standing we have earned in this arena, we are offering a distinct collection of Playground Slides. Features:High quality Fine finish Long lasting Seamless design',
    },
  
   ]
    return(
        <>
        <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Plastic dustbin manufacturers in Pune, Portable Cabin Manufacturers in Pune, Fiber Door Manufacturers in Pune,FRP Planters Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
            {/* <!-- :: Breadcrumb Header --> */}
     <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Playground Slides</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Playground Slide Manufacturers in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

       {/*   ================= Start the Product ===================== */}
       <div className=" mysection1">
        {/* Image Section */}
        <div className="container product-section">
              <h1>Playground Slide Manufacturers in Pune – Nageshwar Fibre And Fabrication Industries</h1>
              <p>When it comes to creating fun, safe, and durable playground equipment, Nageshwar Fibre And Fabrication Industries stands as a leader among the playground slide manufacturers in Pune. With a reputation for quality craftsmanship and innovative designs, we specialize in manufacturing a wide variety of playground slides that bring joy to children and peace of mind to parents and educators.</p>
              </div>
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1 productList">
              
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


     {/*  ================= Contact Details ==================== */}
      <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>

        </>
    )
}