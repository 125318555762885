import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
export default function Benches() {
  const slides = [
    "slider/Slider for product section-01.jpg",
    "slider/Slider for product section-02.jpg",
  ];
  const imgData = [
    {
      img1: "Product-Images/School-bench-manufactures-in-pimpari-chinhwad.jpg",
      name: "Outdoor Garden Benches",
      price: "Rs 16,000 / Piece",
      d1: "Backrest",
      value1: "With Backrest",
      d2: "Arm Rest",
      value2: "Without Arm Rest",
      d3: "Bench Type",
      value3: "With Back",
      d4: "Material",
      value4: "Wooden",
      d5: "Seating Capacity",
      value5: "4 Seater",
      d6: "Appearance",
      value6: "Modern",
      para: "Our Outdoor Garden Benches are crafted with a modern aesthetic, featuring a sturdy wooden construction that ensures high tensile strength and long-lasting durability. Designed for four people, these benches come with a backrest for added comfort. Their compact design makes them easy to install, and they are offered at affordable rates, making them an ideal choice for enhancing outdoor spaces.",
    },
    {
      img1: "Product-Images/Garden-bench-manufcatures-in-pune.jpg",
      name: "Fibre Garden Bench",
      price: "Rs 6,000 / Unit",
      d1: "Seating Capacity",
      value1: "3 Seater",
      d2: "Material",
      value2: "Fibre",
      d3: "Bench Type",
      value3: "Without Back",
      d4: "Size",
      value4: "Length: 6 feet, Height: 2 feet",
      d5: "Design",
      value5: "Plain",
      d6: "Arm Rest",
      value6: "With Arm Rest",
      d7: "Appearance",
      value7: "Modern",
      d8: "Color",
      value8: "All Colors",
      para: "Our Fibre Garden Bench is designed for comfort and durability, accommodating three people with a modern appearance. Made from high-quality fibre material, it features a plain design and includes armrests for added support. This bench is available in various colors and dimensions, ensuring it fits seamlessly into your garden or outdoor space.",
    },
    {
      img1: "Product-Images/School-bench-manufactures-in-pimpari-chinhwad.jpg",
      name: "FRP School Benches",
      price: "Rs 4,000 / Piece",
      d1: "Material",
      value1: "Fibre and Fabrication",
      d2: "Color",
      value2: "All Colors",
      d3: "Seating Capacity",
      value3: "2 Seater",
      d4: "Product Type",
      value4: "School Desk",
      d5: "Size",
      value5: "4 Feet",
      d6: "Country of Origin",
      value6: "Made in India",
      para: "The FRP School Bench is designed for durability and comfort, accommodating two students comfortably. Made from high-quality fibre and fabrication materials, it is available in a variety of colors and features a well-finished design, making it an excellent choice for educational institutions.",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to change slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Automatically move the slide
  useEffect(() => {
    const sliderInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(sliderInterval); // Clean up on component unmount
  }, [currentSlide, 3000]);
  return (
    <>
     <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Nageshwar Fibre And Fabrication Industries| Contact: +91 8793191191, +91 9762431883|Fiber Stage manufacturer in Pune |
          Rain Dance Setup Manufacturers in Pune |
          Playground Equipment Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Stage manufacturer in Pune ,Rain Dance Setup Manufacturers in Pune ,Playground Equipment Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {index === currentSlide && (
              <img src={slide} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>

      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
