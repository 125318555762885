import "./App.css";
import Header from "./Header";
import Home from "./Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer";
import React from "react";
import About from "./About";
import Product from "./Product";
import Service from "./Service";
import Project from "./Project";
import Innovation from "./Innovation";
import Blogs from './Blogs'
import Contact from './Contact';
import AnimalStatue from "./AnimalStatue";
import PlaygroundSlides from "./PlaygroundSlides";
import WaterParkSlide from "./WaterParkSlide";
import WaterSlide from "./WaterSlide";
import ParkSlides from "./ParkSlides";
import WeddingBaggi from "./WeddingBaggi";
import FrpSwimmingPool from "./FrpSwimmingPool";
import FrpPaddleBoat from "./FrpPaddleBoat";
import FiberStages from "./FiberStages";
import  RainDanceSetup from "./RainDanceSetup";
import PlaygroundEquipment from "./PlaygroundEquipment";
import Benches from "./Benches";
import Cabin from "./Cabin";
import Dome from "./Dome";
import Dustbin from "./Dustbin";
import GardenUmbrella from "./GardenUmbrella";
import Table from "./Table";
import Fibertoilet from "./Fibertoilet";
import GardenFountain from "./GardenFountain";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FiberglassPool from "./FiberglassPool";
import Doors from "./Doors";
import Swing from "./Swing";
import Plot from "./Plot";
import FiberStatue from "./FiberStatue";
import ProductPage from "./ProductPage";
import ScrollToTop from './ScrollToTop';
import AboutFounder from "./AboutFounder";
import EnquiryForm from "./EnquiryForm";
import PlaygroundSlidesManufacturesInPune from "./PlaygroundSlidesManufacturesInPune";
import WaterParkSlidesManufacturesInIndia from "./WaterParkSlidesManufacturesInIndia";
import ParkSlidesManufcaturesInPune from "./ParkSlidesManufacturesInPune";
import WaterSlidesManufacturesInIndia from "./WaterSlidesManufacturesInIndia";
import WeddingHorseRathManufacturesInPune from "./WeddingHorseRathManufacturesInPune";
import FRPSwimmingPoolManufactureInIndia from "./FRPSwimmingPoolManufactureInIndia";
import WaterParkEquipmentManufactureInIndia from "./WaterParkEquipmentManufactureInIndia";
import FRPBoatManufactureInIndia from "./FRPBoatManufactureInIndia";
import FiberStageManufacturesInPune from "./FiberStageManufacturesInPune";
import RainDanceSetupManufactureInPune from "./RainDanceSetupManufactureInPune";
import PlaygroundManufcaturesInIndia from "./PlaygroundManufacturesInIndia";
import AnimalFiberStatueInPune from "./AnimalFiberStatueInPune";
import OutdoorPlaygroundEquipmentInPune from "./OutdoorPlaygroundEquipmentInPune";
import UmbrellaManufactureInPune from "./UmbrellaManufactureInPune";
import FiberglassSwimmingPool from "./FiberglassSwimmingPool";
import TubeSlideWaterParkContractor from "./TubeSlideWaterParkContractor";
import FiberPillars from "./FiberPillars";
import WaterManufacturerFountainInpune from "./WaterManufacturerFountainInpune";
import DomesmanufacturesInIndia from "./DomesmanufacturesInIndia";
import GardenDustbin from "./GardenDustbin";
import PortableCabinManufacturesInPune from "./PortableCabinManufacturesInPune";
import FiberDoorManufcatureInPune from "./FiberDoorManufactureInPune";
import FRPPot from "./FRPPot";
import OutdoorSwing from "./OutdoorSwing";
import WeddingDolis from "./WeddingDolis";
import ShivajiMaharajStaueMakerInPune from "./ShivajiMaharajStaueMakerInPune";
import TacklessTrain from "./TacklessTrain";
import JeepRathManufacturesInPune from "./JeepRathManufacturesInPune";
import FamilyRidesManufactures from "./FamilyRidesManufactures";
import FabricatedGateManufactures from "./FabricatedGateManufactures";
import PortableToiletsManufacturesInPune from "./PortableToiletsManufacturesInPune";
import BathroomDoor from "./BathroomDoor";
import FiberSchoolFurniture from "./FiberSchoolFurniture";
import GarbageTruckManufactures from "./GarbageTruckManufactures";
import DesignerWallMural from "./DesignerWallMural";
import MobileToilet from "./MobileToilet";
import TemplechariotManufactures from "./TemplechariotManufactures";
import SportTrampolineManufactures from "./SportsTrampolineManufactures";
import TransmitterCanopy from "./TransmitterCanopy";
import FabricationWork from "./FabricationWork";
import FoodVanManufacture from "./FoodVanManufacture";
import Policy from "./Policy";
import Term from "./Term";
import ImageGallery from "./ImageGallery";
import GalleryImages from "./GalleryImages";
function App() {
  return (
    <>
   
      <Router>
         <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Product></Product>} />
          <Route path="/gallery-images" element={<GalleryImages></GalleryImages>}></Route>
          <Route path="/services" element={<Service></Service>}></Route>
          <Route path="/project-industry" element={<Project></Project>}></Route>
          <Route path="/innovation" element={<Innovation></Innovation>}></Route>
          <Route path="/blogs" element={<Blogs></Blogs>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path='/animal-statue-manufacturer-in-pune' element={<AnimalStatue></AnimalStatue>}></Route>
          <Route path="/play-equipment-manufacturer-in-pune" element={<PlaygroundSlides></PlaygroundSlides>}></Route>
          <Route path="/water-park-slide-manufacturers" element={<WaterParkSlide></WaterParkSlide>}></Route>
          <Route path="/water-slide-manufacturers-in-india" element={<WaterSlide></WaterSlide>}></Route>
          <Route path="/park-slide-manufacturers-in-india" element={<ParkSlides></ParkSlides>}></Route>
          <Route path="/wedding-baggi-manufacturers-in-india" element={<WeddingBaggi></WeddingBaggi>}></Route>
          <Route path="/swimming-pool-manufacturers-in-india" element={<FrpSwimmingPool></FrpSwimmingPool>}></Route>
          <Route path="/paddle-boat-manufacturers-in-india" element={<FrpPaddleBoat></FrpPaddleBoat>}></Route>
          <Route path="/wedding-fiber-stage-manufacturer-in-pune" element={<FiberStages></FiberStages>}></Route>
          <Route path="/rain-dance-setup" element={<RainDanceSetup></RainDanceSetup>}></Route>
          <Route path="/playground-equipment-manufacturers" element={<PlaygroundEquipment></PlaygroundEquipment>}></Route>
          <Route path="/benches-manufacturers-in-pune" element={<Benches></Benches>}></Route>
          <Route path="/portable-cabin-manufacturers-in-pune" element={<Cabin></Cabin>}></Route>
          <Route path="/domes-manufacturer-in-pune" element={<Dome></Dome>}></Route>
          <Route path="/dustbin-manufacturer-in-pune" element={<Dustbin></Dustbin>}></Route>
          <Route path="/garden-umbrella-manufacturers-in-pune" element={<GardenUmbrella></GardenUmbrella>}></Route>
          <Route path="/comercial-dining-table-manufacturers-in-pune" element={<Table></Table>}></Route>
          <Route path="/fiber-toilets-manufacturers-in-pune" element={<Fibertoilet></Fibertoilet>}></Route>
          <Route path="/garden-fountain-manufacturer-in-india" element={<GardenFountain></GardenFountain>}></Route>
          <Route path="/fiberglass-swimming-pool-manufacturers-in-india" element={<FiberglassPool></FiberglassPool>}></Route>
          <Route path="/door-manufacturers-in-pune" element={<Doors></Doors>}></Route>
          <Route path="/merry-go-round-manufacturer-in-pune" element={<Swing></Swing>}></Route>
          <Route path="/frp-pot-manufacture-in-pune" element={<Plot></Plot>}></Route>
          <Route path='/fiber-statue-manufacturers-in-pune' element={<FiberStatue></FiberStatue>}></Route>
          <Route path="/other-product" element={<ProductPage></ProductPage>}></Route>
          <Route path="/about-the-founder" element={<AboutFounder></AboutFounder>}></Route>
          <Route path="/enquiry"  element={<EnquiryForm></EnquiryForm>}></Route>
          <Route path="/policy" element={<Policy></Policy>}></Route>
          <Route path="/terms" element={<Term></Term>}></Route>
          {/* ======================= */}
          <Route path="/Playground-Slide-Manufacturers-in-Pune" element={<PlaygroundSlidesManufacturesInPune></PlaygroundSlidesManufacturesInPune>}></Route>
          <Route path="/Water-Park-Slide-Manufacturers-in-India" element={<WaterParkSlidesManufacturesInIndia></WaterParkSlidesManufacturesInIndia>}></Route>
          <Route path="/Park-Slides-Manufacturer-in-Pune" element={<ParkSlidesManufcaturesInPune></ParkSlidesManufcaturesInPune>}></Route>
          <Route path="/Water-Slides-Manufacturers-in-India" element={<WaterSlidesManufacturesInIndia></WaterSlidesManufacturesInIndia>}></Route>
           <Route path="/Wedding-Horse-Rath-Manufacturer-from-Pune" element={<WeddingHorseRathManufacturesInPune></WeddingHorseRathManufacturesInPune>}></Route>
           <Route path="/FRP-Swimming-Pool-Manufacturers-in-India" element={<FRPSwimmingPoolManufactureInIndia></FRPSwimmingPoolManufactureInIndia>}></Route>
           <Route path="/Water-Park-Equipment-Manufacturers-in-India" element={<WaterParkEquipmentManufactureInIndia></WaterParkEquipmentManufactureInIndia>}></Route>
           <Route path="/FRP-Boat-Manufacturers-in-India" element={<FRPBoatManufactureInIndia></FRPBoatManufactureInIndia>}></Route>
           <Route path="/Fiber-Stage-manufacturer-in-Pune" element={<FiberStageManufacturesInPune></FiberStageManufacturesInPune>}></Route>
           <Route path="/Rain-Dance-Setup-Manufacturers-in-Pune" element={<RainDanceSetupManufactureInPune></RainDanceSetupManufactureInPune>}></Route>
           <Route path="/Playground-Equipment-Manufacturers-in-India" element={<PlaygroundManufcaturesInIndia></PlaygroundManufcaturesInIndia>}></Route>
           <Route path="/Animal-and-Fiber-Statue-Manufacturers-in-Pune" element={<AnimalFiberStatueInPune></AnimalFiberStatueInPune>}></Route>
           <Route path="/Outdoor-Playground-Equipment-Manufacturers-in-India" element={<OutdoorPlaygroundEquipmentInPune></OutdoorPlaygroundEquipmentInPune>}></Route>
           <Route path="/Umbrella-Manufacturers-in-Pune" element={<UmbrellaManufactureInPune></UmbrellaManufactureInPune>}></Route>
           <Route path="/Fiberglass-Swimming-Pool-Contractors-in-Pune" element={<FiberglassSwimmingPool></FiberglassSwimmingPool>}></Route>
           <Route path="/Tube-Slide-Water-Park-Contractor-in-Pune" element={<TubeSlideWaterParkContractor></TubeSlideWaterParkContractor>}></Route>
           <Route path="/Fiber-Pillar-Manufacturers-in-Pune" element={<FiberPillars></FiberPillars>}></Route>
           <Route path="/Water-Fountain-Manufacturers-in-Pune" element={<WaterManufacturerFountainInpune></WaterManufacturerFountainInpune>}></Route>
           <Route path="/frp-Dome-Manufacturers-in-India" element={<DomesmanufacturesInIndia></DomesmanufacturesInIndia>}></Route>
           <Route path="/Plastic-dustbin-manufacturers-in-Pune"  element={<GardenDustbin></GardenDustbin>}></Route>
           <Route path="/Portable-Cabins-Manufacturers-in-Pune" element={<PortableCabinManufacturesInPune></PortableCabinManufacturesInPune>}></Route>
           <Route path="/Fiber-Door-Manufacturers-in-Pune" element={<FiberDoorManufcatureInPune></FiberDoorManufcatureInPune>}></Route>
           <Route path="/frp-Planters-Manufacturers-in-Pune" element={<FRPPot></FRPPot>}></Route>
           <Route path="/Outdoor-Swing-Manufacturers-in-Pune" element={<OutdoorSwing></OutdoorSwing>}></Route>
           <Route path="/Wedding-Doli-Manufacturer-in-Pune" element={<WeddingDolis></WeddingDolis>}></Route>
           <Route path="/Shivaji-Maharaj-Statue-Maker-in-Pune" element={<ShivajiMaharajStaueMakerInPune></ShivajiMaharajStaueMakerInPune>}></Route>
           <Route path="/Trackless-Train-Manufacturers-in-Pune" element={<TacklessTrain></TacklessTrain>}></Route>
           <Route path="/frp-Jeep-Rath-Manufacturers-in-Pune" element={<JeepRathManufacturesInPune></JeepRathManufacturesInPune>}></Route>
           <Route path="/Family-Rides-at-Thorpe-Park-Manufacturers-in-India" element={<FamilyRidesManufactures></FamilyRidesManufactures>}></Route>
           <Route path='/Fabricated-Gate-Manufacturers-in-Pune' element={<FabricatedGateManufactures></FabricatedGateManufactures>}></Route>
           <Route path="/frp-Portable-Toilets-and-Urinals-Manufacturers-in-Pune" element={<PortableToiletsManufacturesInPune></PortableToiletsManufacturesInPune>}></Route>
           <Route path="/Fiber-Bathroom-Door-Manufacturers-in-Pune" element={<BathroomDoor></BathroomDoor>}></Route>
           <Route path="/Fibre-School-Furniture-Manufacturers-in-Pune" element={<FiberSchoolFurniture></FiberSchoolFurniture>}></Route>
           <Route path="/Garbage-Truck-Manufacturers-in-Pune" element={<GarbageTruckManufactures></GarbageTruckManufactures>}></Route>
           <Route path="/Designer-Wall-Mural-Manufacturers-in-Pune" element={<DesignerWallMural></DesignerWallMural>}></Route>
           <Route path="/frp-Mobile-Toilet-Manufacturer-in-Pune" element={<MobileToilet></MobileToilet>}></Route>
           <Route path="/frp-Temple-Chariot-Manufacturers-in-Pune" element={<TemplechariotManufactures></TemplechariotManufactures>}></Route>
           <Route path="/Sports-Trampoline-Manufacturers-in-India" element={<SportTrampolineManufactures></SportTrampolineManufactures>}></Route>
           <Route path="/frp-Transmitter-Canopy-Manufacturers-in-Pune" element={<TransmitterCanopy></TransmitterCanopy>}></Route>
           <Route path="/Fabrication-Companies-in-Pune" element={<FabricationWork></FabricationWork>}></Route>
           <Route path="/Food-van-Manufacturers-in-Pune" element={<FoodVanManufacture></FoodVanManufacture>}></Route>
           <Route path="/imagegallery" element={<ImageGallery></ImageGallery>}></Route>
        </Routes>
        <Footer/>
      </Router>

     
    </>
  );
}

export default App;
