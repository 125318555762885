import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// Import icons from React Icons
import {
  FaPencilRuler,
  FaDraftingCompass,
  FaPalette,
  FaUserTie,
  FaTools,
  FaHeadset,
} from "react-icons/fa";

export default function Service() {
  const services = [
    {
      title: "Designing",
      icon: <FaPencilRuler size={50} color="#692a30" />, // Icon for Designing
    },
    {
      title: "Planning",
      icon: <FaDraftingCompass size={50} color="#692a30"  />, // Icon for Planning
    },
    {
      title: "Theming",
      icon: <FaPalette size={50} color="#692a30"  />, // Icon for Theming
    },
    {
      title: "Consultancy",
      icon: <FaUserTie size={50} color="#692a30" />, // Icon for Consultancy
    },
    {
      title: "Maintenance",
      icon: <FaTools size={50} color="#692a30" />, // Icon for Maintenance
    },
    {
      title: "Support",
      icon: <FaHeadset size={50} color="#692a30" />, // Icon for Support
    },
  ];

  return (
    <>
      <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191,+91 9762431883|  Nageshwar Fibre And Fabrication Industries|Trackless Train Manufacturers in Pune | frp Jeep Rath
          Manufacturers in Pune | frp Portable Toilets and Urinals Manufacturers
          in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Trackless Train Manufacturers in Pune , frp Jeep Rath Manufacturers in Pune
          ,frp Portable Toilets and Urinals Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <section
        className="breadcrumb-header breadcrumb-header-2 gallery-image1"
        id="page"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Service</h1>
                <ul>
                  {/* Use Link component if routing is set up */}
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========================== */}
      <section id="services" className="service-section">
        <h2 className="text-brown">Our Services</h2>
        <div className="service-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <div className="service-icon">{service.icon}</div>
              <h3>{service.title}</h3>
            </div>
          ))}
        </div>
        <div class="container service-section">
          <div class="row service-box">
            <div class="col-12 col-md-12 col-lg-6 ">
              <img src="slider/designing.jpg" className="" alt="" />
            </div>
            <div class="col-12 col-md-12 col-lg-6 service-img">
              <h2 className="">Designing</h2>
              <p>
                At Nageshwar Fibre & Fabrication Industries, our designing
                process is where creativity meets functionality. We specialize
                in crafting innovative designs that not only fulfill the
                aesthetic aspirations of our clients but also adhere to high
                standards of safety and usability. Our design team is equipped
                with the latest technology and tools to bring your ideas to
                life, ensuring that each product is customized to meet your
                specific requirements.
              </p>
              <ul>
                <li>
                  <strong>Custom Designs:</strong> Tailored to fit your needs
                  and environment.
                </li>
                <li>
                  <strong>Sustainability:</strong> Utilizing eco-friendly
                  materials and processes.
                </li>
                <li>
                  <strong>Precision:</strong> Ensuring every detail is
                  meticulously planned and executed.
                </li>
                <li>
                  <strong>Innovation:</strong> Incorporating the latest trends
                  and technologies to create modern, forward-thinking designs.
                </li>
              </ul>
            </div>
          </div>
          {/* ====================== Planning Section ============= */}
          <div class="row service-box">
            <div class="col-12 col-md-12 col-lg-6 service-img">
              <h2>Planning</h2>
              <p>
                At <strong>Nageshwar Fibre & Fabrication Industries</strong>, we
                believe that great projects are built on a foundation of
                meticulous planning. Our planning process ensures that every
                project is executed efficiently, on time, and within budget. We
                collaborate closely with our clients to understand their needs
                and create a comprehensive plan that addresses all aspects of
                the project, from design and material selection to production
                and installation.
              </p>
              <ul>
                <li>
                  <strong>Client Collaboration:</strong> Understanding your
                  requirements and goals is the first step in our planning
                  process.
                </li>
                <li>
                  <strong>Project Roadmaps:</strong> Clear timelines and
                  milestones ensure that every stage of the project is
                  well-organized.
                </li>
                <li>
                  <strong>Risk Management:</strong> We anticipate challenges and
                  put in place contingency plans to mitigate risks.
                </li>
                <li>
                  <strong>Resource Optimization:</strong> Our planning maximizes
                  the use of materials and labor to deliver cost-effective
                  solutions without compromising on quality.
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-12 col-lg-6">
              <img src="slider/planning.jpg" className="" alt="" />
            </div>
          </div>

          {/* ======================= Theming ================ */}
          <div class="row service-box">
            <div class="col-12 col-md-12 col-lg-6">
              <img src="slider/theimng.jpg" className="" alt="" />
            </div>
            <div class="col-12 col-md-12 col-lg-6 service-img">
              <h3>Theming</h3>
              <p>
                At <strong>Nageshwar Fibre & Fabrication Industries</strong>, we
                excel in bringing imaginative ideas to life through custom
                theming services. Our expertise lies in creating unique and
                captivating themes for a wide range of environments, whether
                it’s for commercial spaces, recreational areas, or special
                events. With a keen understanding of design aesthetics and
                fabrication techniques, we ensure that every theme is executed
                with precision and creativity.
              </p>
              <ul>
                <li>
                  <strong>Customized Themes:</strong> Tailored themes to match
                  the vision and purpose of the space.
                </li>
                <li>
                  <strong>Innovative Designs:</strong> Utilizing cutting-edge
                  techniques and creative concepts to deliver memorable
                  experiences.
                </li>
                <li>
                  <strong>High-Quality Materials:</strong> We use the best
                  materials to ensure durability and a long-lasting visual
                  impact.
                </li>
                <li>
                  <strong>Attention to Detail:</strong> Our meticulous attention
                  to detail guarantees that every element of the theme is
                  cohesive and visually striking.
                </li>
              </ul>
            </div>
          </div>

          {/* ========================== Consultancy ================== */}
          <div class="row service-box">
            <div class="col-12 col-md-12 col-lg-6 service-img">
              <h3>Consultancy</h3>
              <p>
                At <strong>Nageshwar Fibre & Fabrication Industries</strong>, we
                offer expert consultancy services to guide you through every
                phase of your project. From conceptualization to final
                implementation, our experienced consultants provide invaluable
                insights that ensure your project's success. Whether you’re
                looking to develop a new product, upgrade an existing structure,
                or optimize manufacturing processes, our consultancy team
                delivers tailored solutions to meet your specific needs.
              </p>
              <ul>
                <li>
                  <strong>Expert Guidance:</strong> Leveraging years of industry
                  experience to provide sound advice.
                </li>
                <li>
                  <strong>Feasibility Studies:</strong> Assessing the
                  practicality and profitability of your projects before moving
                  forward.
                </li>
                <li>
                  <strong>Problem-Solving:</strong> Offering innovative
                  solutions to overcome design, material, or production
                  challenges.
                </li>
                <li>
                  <strong>Process Optimization:</strong> Ensuring efficient and
                  cost-effective fabrication processes that align with industry
                  standards.
                </li>
              </ul>
            </div>

            <div class="col-12 col-md-12 col-lg-6  img-service">
              <img src="slider/consulting.jpg" className="" alt="" />
            </div>
          </div>

          {/* ======================== support section ================= */}
          <div class="row service-box">
            <div class="col-12 col-md-12 col-lg-6  img-service">
              <img src="slider/support.jpg" className="" alt="" />
            </div>
            <div class="col-12 col-md-12 col-lg-6 service-img">
              <h3>Maintenance and Support</h3>
              <p>
                At <strong>Nageshwar Fibre & Fabrication Industries</strong>, we
                understand that the longevity and performance of our products
                rely heavily on effective maintenance and support. Our dedicated
                team is committed to providing comprehensive maintenance
                services that ensure your installations remain in optimal
                condition throughout their lifespan. Whether you require routine
                maintenance, emergency repairs, or technical support, we are
                here to assist you at every step.
              </p>
              <ul>
                <li>
                  <strong>Routine Maintenance:</strong> Regular check-ups and
                  servicing to prevent potential issues and extend the life of
                  your products.
                </li>
                <li>
                  <strong>Emergency Repairs:</strong> Quick response times to
                  address urgent problems, minimizing downtime and disruption to
                  your operations.
                </li>
                <li>
                  <strong>Technical Support:</strong> Access to knowledgeable
                  support staff who can assist you with any questions or
                  technical challenges you may encounter.
                </li>
                <li>
                  <strong>Custom Maintenance Plans:</strong> Tailored service
                  agreements that meet your specific needs and ensure your
                  equipment operates smoothly and efficiently.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* ========================= */}
    </>
  );
}
