import React from "react";
import "./Policy.css";
import { Link } from "react-router-dom";

const Policy = () => {
  return (
    <>
      <section
        className="breadcrumb-header breadcrumb-header-2 gallery-image"
        id="page"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Privacy & Policy</h1>
                <ul>
                  {/* Use Link component if routing is set up */}
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Privacy & Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ */}
      <section className="container">
        <div class="row">
          <div class="col-12 col-md-8">
            <div className="privacy-policy-container">
             <div className="mb-4">
                <img src="products-img/Privacy and Policy Section Image-01.jpg" alt="policy img"/>
             </div>
              <p>
                At Nageshwar Fibre and Fabrication, accessible from
                www.nageshwarfiber.com, one of our main priorities is the
                privacy of our visitors. This Privacy Policy document contains
                types of information that are collected and recorded by
                Nageshwar Fibre and Fabrication and how we use it.
              </p>

              <h2>1. Information We Collect</h2>
              <p>
                We collect various types of personal information such as name,
                email, phone number, and message through our enquiry form. The
                reasons for collecting this data will be made clear at the point
                we ask you to provide it.
              </p>

              <h2>2. How We Use Your Information</h2>
              <p>
                The information we collect is used in the following ways:
                <ul>
                  <li>
                    To provide customer support and respond to your enquiries
                  </li>
                  <li>To improve our website and services</li>
                  <li>To send you updates or promotional emails</li>
                </ul>
              </p>

              <h2>3. Data Security</h2>
              <p>
                We implement a variety of security measures to maintain the
                safety of your personal information. However, no method of
                transmission over the internet or electronic storage is 100%
                secure.
              </p>

              <h2>4. Third-Party Services</h2>
              <p>
                We do not share your personal information with third parties
                except for cases where it is necessary for providing the
                services you requested, or for legal obligations.
              </p>

              <h2>5. Your Rights</h2>
              <p>
                You have the right to access, update, or delete your personal
                information. Please contact us at info@nageshwarfiber.com if you
                would like to exercise any of these rights.
              </p>

              
            </div>
          </div>
          <div class="col-12 col-md-4 ">
          <div className="">
          <div className="contact-box">
            <h3>Contact Number</h3>
            <p><a href="tel:+91 8793191191">+91 8793191191</a></p>
            <p><a href="tel:+91 9762431883">+91 9762431883</a></p>
          </div>
          <div className="contact-box">
            <h3>Address</h3>
            <p className=''>Nageshwar Fibre And Fabrication Industries</p>
           <p>Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India</p>
          </div>
          <div className="contact-box">
            <h3>Email</h3>
            <p>info@nageshwarfiber.com</p>
          </div>

          <div className="contact-box">
          <div
                  style={{ width: "100%", height: "200px" }}
                  className="map-box"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3779.1811796199286!2d74.14376007519606!3d18.700704982428153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQyJzAyLjUiTiA3NMKwMDgnNDYuOCJF!5e0!3m2!1sen!2sin!4v1728016394392!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Embed"
                  ></iframe>
                </div>
          </div>
        </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Policy;
