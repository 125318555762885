import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function FiberglassSwimmingPool(){
    const imgData = [
        {
            img1: 'Product-Images/Fiberglass-swimming-pool-manufactures-near-me.jpg',
            name: 'Fiberglass Swimming Pool',
            price: 'Rs 650 / sq ft Get Best Price',
            d1: 'Type',
            value1: 'Fiberglass Swimming',
            d2: 'Country of Origin',
            value2: 'Made in India',
            d3: 'Warranty',
            value3: '10 Year (Self Life 25 Years)',
            d4: 'Thickness',
            value4: '12mm',
            d5: 'Material',
            value5: 'FRP',
            d6: 'Color',
            value6: 'Blue',
            d7: 'Voltage',
            value7: '230V',
            d8: 'Model Name/Number',
            value8: '816',
            d9: 'Total Jet',
            value9: 'Turbo Jet',
            d10: 'Power',
            value10: 'Single Phase',
            d11: 'Installation',
            value11: 'Included',
            d12: 'Weight',
            value12: '1000kg',
            d13: 'Filter Pump',
            value13: 'Included',
            d14: 'Pool Type',
            value14: 'Outdoor',
            d15: 'Design Type',
            value15: 'Rectangular',
            d16: 'Capacity',
            value16: 'Approx 16000 Ltr',
            d17: 'Usage/Application',
            value17: 'Amusement Park',
            d18: 'Features',
            value18: 'ISO NPG Gel Coat Finish',
            d19: 'Height',
            value19: '5 Ft',
            para: 'This high-quality Fiberglass Swimming Pool, manufactured by Nageshwar Fibre & Fabrication Industries, is perfect for outdoor use in amusement parks and residential areas. Made with durable FRP material, it offers a self-life of 25 years and comes with a 10-year warranty. The pool is designed with a turbo jet system and comes with included installation and a filter pump for convenience.'
        }
        
    ]
    return(
        <>
         <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
        Contact: +91 8793191191, +91 9762431883|Nageshwar Fibre And Fabrication Industries|Umbrella Manufacturers in Pune
          | Fiberglass Swimming Pool Contractors in Pune | Tube Slide Water Park Contractor in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Umbrella Manufacturers in Pune, Fiberglass Swimming Pool Contractors in Pune ,Tube Slide Water Park Contractor in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
        <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Fiberglass Swimming Pools</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Fiberglass Swimming Pool Contractors in Pune
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

            {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
          Fiberglass Swimming Pools – Nageshwar Fibre And
            Fabrication Industries
          </h1>
          <p>Fiberglass Swimming Pools by Nageshwar Fibre and Fabrication Industries are a premium choice for anyone looking to install high-quality, durable, and easy-to-maintain pools in outdoor spaces such as amusement parks, resorts, or private homes. With a focus on robust design and longevity, these pools are crafted using FRP (Fiberglass Reinforced Plastic), ensuring they can withstand wear and tear while offering a sleek, modern appearance.

</p>
        </div>
       

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                        <tr>
                          <th>{e.d11}</th>
                          <td>{e.value11}</td>
                        </tr>

                        <tr>
                          <th>{e.d12}</th>
                          <td>{e.value12}</td>
                        </tr>

                        <tr>
                          <th>{e.d13}</th>
                          <td>{e.value13}</td>
                        </tr>
                        <tr>
                          <th>{e.d14}</th>
                          <td>{e.value14}</td>
                        </tr>
                        <tr>
                          <th>{e.d15}</th>
                          <td>{e.value15}</td>
                        </tr>
                        <tr>
                          <th>{e.d16}</th>
                          <td>{e.value16}</td>
                        </tr>
                        <tr>
                          <th>{e.d17}</th>
                          <td>{e.value17}</td>
                        </tr>
                        <tr>
                          <th>{e.d18}</th>
                          <td>{e.value18}</td>
                        </tr>
                   
                      

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>

      {/*  ================= Contact Details ==================== */}
      <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        </>
    )
}