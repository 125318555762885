import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";

export default function UmbrellaManufactureInPune(){
    const imgData = [
        {
            img1: 'Product-Images/Umbrella-manufcatures-in-pune.jpg', 
            name: 'Fiber Water Park Umbrella',
            price: 'Rs 45,000 / Piece Get Best Price',
            d1: 'Exit',
            value1: 'Pool',
            d2: 'Color',
            value2: 'All colors',
            d3: 'Material',
            value3: 'Fiber and fabrication structure',
            d4: 'Brand',
            value4: 'NFF Industries',
            d5: 'Height',
            value5: '12 feet',
            d6: 'Is It Customized',
            value6: 'Non Customized',
            d7: 'Water Pump',
            value7: 'No',
            d8: 'Standard',
            value8: 'FRP',
            d9: 'Design',
            value9: 'Modern',
            d10: 'I Deal In',
            value10: 'New Only',
            d11: 'Country of Origin',
            value11: 'Made in India',
            para: 'The Fiber Water Park Umbrella, made from heavy fiber and fabrication material, is designed to enhance your outdoor experience, providing ample shade around pool areas with its modern design.'
        },
       
        {
            img1: 'Product-Images/Outdoor FRP Umbrella.jpg', 
            name: 'Outdoor FRP Umbrella',
            price: 'Rs 60,000 / Piece Get Best Price',
            d1: 'Material',
            value1: 'Plastic',
            d2: 'Height',
            value2: '12 Feet',
            d3: 'Canopy Size',
            value3: '25 ft',
            d4: 'Umbrella Style',
            value4: 'Cantilever',
            d5: 'Umbrella Shape',
            value5: 'Round',
            d6: 'Product Type',
            value6: 'Outdoor',
            d7: 'Surface Finish',
            value7: 'Coated',
            d8: 'Pole/Shaft Material',
            value8: 'Metal',
            d9: 'Umbrella Base Included',
            value9: 'Yes',
            d10: 'Ribs Material',
            value10: 'Stainless Steel',
            d11: 'Country of Origin',
            value11: 'Made in India',
            para: 'With the aid of our competent professionals and modular production unit, we have been able to provide an optimum quality assortment of Outdoor Garden Umbrella, designed to enhance outdoor spaces while offering protection from the sun.'
        },]
    return(
        <>
        <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191,
          +91 9762431883| Nageshwar Fibre And Fabrication Industries| Trackless Train Manufacturers in Pune | frp Jeep Rath
          Manufacturers in Pune | frp Portable Toilets and Urinals Manufacturers
          in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Trackless Train Manufacturers in Pune , frp Jeep Rath Manufacturers in Pune
          ,frp Portable Toilets and Urinals Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

       <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Garden Umbrella</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li> Garden Umbrella - Umbrella Manufacturers in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
        {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
          Umbrella Manufacturers in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
         <p>
         Nageshwar Fibre and Fabrication Industries, based in Pune, is a leading manufacturer of high-quality umbrellas designed for various outdoor purposes. The company is renowned for producing durable and stylish umbrellas that cater to different industries and applications, including commercial spaces, resorts, outdoor cafes, gardens, and more.
         </p>
        </div>

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


       {/*  ================= Contact Details ==================== */}
       <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>

        </>
    )
}