import React from 'react';
import './CSS/style.css';
import './CSS/animate.css';
import './CSS/bootstrap.min.css';
import './CSS/lity.min.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import "./App.css"


// BlogItem Component
const BlogItem = ({ imgSrc, category, title, date, description, link }) => (
  <div className="col-md-6 col-lg-4">
    <div className="blog-item">
      <div className="img-box">
        <Link href={link} className="open-item-blog"><i className="fas fa-arrow-right"></i></Link>
        <Link href={link} className="open-post">
          <img className="img-fluid" src={imgSrc} alt={title} />
        </Link>
        <ul>
          {category.map((cat, index) => (
            <li key={index}>
              <Link href={link}>{cat}</Link>
              {index < category.length - 1 && <a href={link}>,</a>}
            </li>
          ))}
        </ul>
      </div>
      <div className="text-box">
        <Link href={link} className="title-blog">
          <h5>{title}</h5>
        </Link>
        <span className="blog-date">{date}</span>
        <p>{description}</p>
        {/* <a href={link} className="link">Read More</a> */}
        <Link to={link} className='link'>Read More</Link>
      </div>
    </div>
  </div>
);

// Blog Section Component
const BlogSection = () => {
  const blogs = [
    {
      imgSrc: "products-img/Inflatable-water-slide-for-kids-and-adults-for-sales.jpg",
      category: ["Water Slides"],
      title: "Inflatable water slide for kids and adults for sales",
      description: "Nageswar Fibre &Fabrication Industries is Listed amongst the reckoned manufactures of aqualitative range of products like Garden dustbins,Portable Cabinsamd much more.our whole product Assortment is designed and manafactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiorityin Quality.",
      link: "/Water-Slides-Manufacturers-in-India"
    },
    {
      imgSrc: "products-img/Animal-and-Fiber-statue-mnaufacturing-in-pune.jpg",
      category: ["Animal Statue"],
      title: "Animal and Fibre statue manufacturing in pune for Sales",
      description: "Nageshwar Fibre and Fabrication Industries in Pune is a prominent name in the manufacturing of animal and fibre statues. Renowned for their artistic craftsmanship and attention to detail, Nageshwar Fibre specializes in creating high-quality statues that are both durable and visually stunning. ",
      link: "/Animal-and-fiber-statue-manufacturers-in-pune"
    },
    {
      imgSrc: "products-img/FRP-Playground-Slides-Manufactures-in-pune.jpg",
      category: ["FRP Playground Slides Manufactures"],
      title: "FRP Playground Slides Manufactures in pune for sales",
      description: "When it comes to creating fun, safe, and durable playground equipment, Nageshwar Fibre And Fabrication Industries stands as a leader among the playground slide manufacturers in Pune.",
      link: "/Playground-Slide-Manufacturers-in-Pune"
    }
  ];

  return (
    <>
       <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar Fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
           Contact: +91 8793191191, +91 9762431883|Nageshwar Fibre And Fabrication Industries|Fibre Stage manufacturer in Pune |
          Rain Dance Setup Manufacturers in Pune |
          Playground Equipment Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar Fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fibre Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fibre Stage manufacturer in Pune ,Rain Dance Setup Manufacturers in Pune ,Playground Equipment Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fibre and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>


    <section className="blog py-100-70">
      <div className="container">
        <div className="sec-title">
          <div className="row">
            <div className="col-lg-4">
              <h2>Blog</h2>
              <h3>Our Blogs</h3>
            </div>
            <div className="col-lg-5 col-md-8 d-flex align-items-center">
             <p>At Nageshwar Fibre and Fabrication, we understand the importance of delivering tailor-made solutions to suit specific business needs.</p>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-center justify-content-end button-sec">
              <Link to="blogs" className="btn-1 btn-3">All Blogs</Link>
            </div>
          </div>
        </div>
        <div className="row">
          {blogs.map((blog, index) => (
            <BlogItem key={index} {...blog} />
          ))}
        </div>
      </div>
    </section>
    </>
  );
};

export default BlogSection;
