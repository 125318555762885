import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Helmet } from 'react-helmet';
import "./Enquiry.css";
import Swal from 'sweetalert2';

const EnquiryForm = () => {
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
    product: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const products = [
    "Playground Slides",
    "Park Slides",
     "Water Slides",
    "Wedding Baggi",
    "Frp Swimming Pool",
    "Water Park Equipment",
    "Frp Paddle Boat",
     "Fiber Stages",
    "Rain Dance Setup",
    "Playground Equipment",
    "Animal And Fiber Statue",
    "Outdoor Play Equipments",
    "Garden Umbrella",
    "Fiberglass Swimming Pools",
    "Tube Slide",
    "Fiber Pillars",
    "Amusement Park Rides",
     "Commercial Dining Table",
     "Other"

 
  ];

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "c730eb9f-95e0-4f01-908a-abeb40b305df");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
    }
  };

  const showAlert = () => {
    Swal.fire({
      title: 'Success!',
      text: 'You clicked the button.',
      icon: 'success',
      confirmButtonText: 'OK'
    });
  };



  return (
    <>

<Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
         Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries|Fiber Stage manufacturer in Pune |
          Rain Dance Setup Manufacturers in Pune |
          Playground Equipment Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Stage manufacturer in Pune ,Rain Dance Setup Manufacturers in Pune ,Playground Equipment Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
    <section className="breadcrumb-header gallery-image" id="page" >
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="banner">
                            <h1>Enquiry</h1>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i className="fas fa-angle-right"></i>
                                </li>
                                <li>Enquiry</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <div class="row container enquiry-section">
        <div class="col-12 col-md-6">
          {/* Contact Details Section */}
        <div className="contact-details-container">
          <div className="contact-box">
            <h3>Contact Number</h3>
            <p><a href="tel:+91 8793191191">+91 8793191191</a></p>
            <p><a href="tel:+91 9762431883">+91 9762431883</a></p>
          </div>
          <div className="contact-box">
            <h3>Address</h3>
            <p className=''>Nageshwar Fibre And Fabrication Industries</p>
           <p>Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India</p>
          </div>
          <div className="contact-box">
            <h3>Email</h3>
            <p>info@nageshwarfiber.com</p>
          </div>
        </div>
        </div>
        <div class="col-12 col-md-6">
        <div className="enquiry-form">
      <h2 className='form-head'>Enquiry Form</h2>
      <form onSubmit={onSubmit}>
      {/* <h2>Contact Us</h2> */}
      <div>
        <label>Name:</label>
        <input
          type="text"
          name="name"
  
          
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
       
          required
        />
      </div>
      <div>
        <label>Phone Number:</label>
        <input
          type="tel"
          name="phone"
        
         
          required
        />
      </div>
      
      <div>
        <label>Message : </label>
        <textarea
          name="message"
       
          required
        />
      </div>
      <div>
        <label>Select Product:</label>
        <select
          name="product"
         
          required
        >
          <option value="">-- Select a Product --</option>
          {products.map((product) => (
            <option key={product} value={product}>
              {product}
            </option>
          ))}
        </select>
      </div>
      <button type="submit" className='btn-submit' onClick={showAlert}>Submit</button>
    </form>
    </div>
        </div>
    </div>

    <div>
    <div
                  style={{ width: "100%", height: "300px" }}
                  className="map-box"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3779.1811796199286!2d74.14376007519606!3d18.700704982428153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQyJzAyLjUiTiA3NMKwMDgnNDYuOCJF!5e0!3m2!1sen!2sin!4v1728016394392!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Embed"
                  ></iframe>
                </div>
    </div>
    
    </>
  );
};

export default EnquiryForm;
