import './CSS/style.css';
import './CSS/animate.css';
import './CSS/bootstrap.min.css';
import './CSS/lity.min.css';
import './App.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from "react";
import "./Slider.css";


export default function Project(){
  const slides = [
    "slider/Slider for product section-01.jpg",
    "slider/Slider for product section-02.jpg",
  ];
    const settings = {
        dots: true, // Show dots for navigation
        infinite: true, // Infinite looping
        speed: 500, // Transition speed
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll
        autoplay:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2, // Adjust for smaller screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1, // Show one slide on mobile
              slidesToScroll: 1,
            },
          },
        ],
    };

 

    const projects = [
      {
        name: '5 Platform waer fun play system',
        image: "projects/water park project/5 platform waer fun play system.jpg",
      },
      {
        name: 'Amusement park water slide',
        image: 'projects/water park project/Amusement park water slide.jpg',
      },
      {
        name: 'frp Combination Water Slide',
        image: 'projects/water park project/frp-combination-water-slide-500x500.jpg',
      },
      {
        name: 'FRP Tube Slide',
        image: 'projects/water park project/frp-tube-slide-1000x1000.jpg',
      },
      {
        name: 'Thunder Bowl Water Slide',
        image: 'projects/water park project/thunder-bowl-water-slide-1000x1000.jpg',
      },
      {
        name:'Water Park Equipment',
        image:'projects/water park project/Water park equipment.jpg'
      },
      {
        name:'Water park Equipment',
        image:'projects/water park project/water park equipment1.jpg'
      },{
        name:'Fiber water park equipment',
        image:'projects/water park project/water-park-equipment-1000x1000.jpg'
      }
    ];


    const project1 =[
      {
        name:'Project 1',
        image:'projects/playground slides/Airplane slide.jpg'
      },
      {
        name:'Project 2',
        image:'projects/playground slides/playground-slides-1000x1000 (1).jpg'
      },
      {
        name:'Project 3',
        image:'projects/playground slides/playground-slides-1000x1000.jpg'
      },
      {
        name:'Project 4',
        image:'projects/playground slides/swimming curved slide.jpg'
      },
      {
        name:'Project 5',
        image:'projects/playground slides/water-park-slide-1000x1000.jpg'
      }
    ]

    const project2 =[
      {
        name:'Project 1',
        image:'projects/swimming pool/fiberglass swimming pool.jpg'
      },
      {
        name:'Project 2',
        image:'projects/swimming pool/frp-portable-swimming-pool-500x500.jpg'
      },
      {
        name:'Project 3',
        image:'projects/swimming pool/product-jpeg-500x500.jpg'
      },
      {
        name:'Project 4',
        image:'projects/swimming pool/readymade-frp-swimming-pool-500x500.jpg'
      },
      {
        name:'Project 5',
        image:'projects/swimming pool/readymade-swimming-pool-500x500 (1).jpg'
      },
      {
        name:'Project 6',
        image:'projects/swimming pool/water-park-wave-pool-500x500.jpg'
      }
    ]


    const images = [
      'Product-Images/2-Seater FRP Swan Paddle Boat.jpg',
      'Product-Images/3d-Personalized-crystal-gift-manufactures-in-india.jpg',
      'Product-Images/4-Seater FRP Paddle Boat.jpg',
      'Product-Images/4-Seater Swan Paddle Boat.jpg',
      'Product-Images/Amusement-park-equipment-manufactures-in-india.jpg',
      'Product-Images/Designer-wall-mural-manufactures-in-pune.jpg',
      'Product-Images/Elephant-statue-manufactures-in-pune.jpg',
      'Product-Images/Fiber Dome.jpg',
      'Product-Images/Fiber Kolombus Ride.jpg'

    ];
    const [currentSlide, setCurrentSlide] = useState(0);

    // Function to change slide
    const nextSlide = () => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    };
  
    // Automatically move the slide
    useEffect(() => {
      const sliderInterval = setInterval(nextSlide, 3000);
      return () => clearInterval(sliderInterval); // Clean up on component unmount
    }, [currentSlide, 3000]);
    return(
        <>
         <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
           Contact: +91 8793191191, +91 9762431883|Nageshwar Fibre And Fabrication Industries|Outdoor Swing Manufacturers in Pune | Wedding Doli Manufacturer in Pune | Shivaji Maharaj Statue Maker in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Outdoor Swing Manufacturers in Pune, Wedding Doli Manufacturer in Pune, Shivaji Maharaj Statue Maker in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>


<div className="slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {index === currentSlide && (
              <img src={slide} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>



{/* ====================== project slider ============== */}
<section className='project-section1'>
<div className="project-carousel">
      <h2>Water Park Equipment</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            
            </div>
          </div>
        ))}
      </Slider>
    </div>

    <div className="project-carousel">
      <h2>Playground Slides</h2>
      <Slider {...settings}>
        {project1.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            
            </div>
          </div>
        ))}
      </Slider>
    </div>

    <div className="project-carousel">
      <h2>FRP Portable Swimming Pool</h2>
      <Slider {...settings}>
        {project2.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            
            </div>
          </div>
        ))}
      </Slider>
    </div>



    <div className="scrolling-images-container">
      <div className="scrolling-images">
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Scrolling ${index}`} />
        ))}
        {/* Duplicate images for infinite scroll effect */}
        {images.map((src, index) => (
          <img key={`dup-${index}`} src={src} alt={`Scrolling ${index}`} />
        ))}
      </div>
      </div>
</section>

    
        </>
    )
}