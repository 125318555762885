import React, { useState } from 'react';
import './ImageGallery.css'; // Optional: create a CSS file for styling

const images = [
  { id: 1, src: 'gallery/Fibre umbrella.jpg', alt: 'Image 1' },
  { id: 2, src: 'gallery/fountain.jpg', alt: 'Image 2' },
  { id: 3, src: 'gallery/FRP Domes.jpg', alt: 'Image 3' },
  { id: 3, src: 'gallery/Trampoline.jpg', alt: 'Image 3' },
  { id: 3, src: 'gallery/FRP pillars.jpg', alt: 'Image 3' },
  { id: 3, src: 'gallery/Portable swimming pool.jpg', alt: 'Image 3' },
  { id: 3, src: 'gallery/tube slider.jpg' },
  { id: 3, src: 'gallery/water park slides.jpg' },
  { id: 3, src: 'gallery/playground equipment.jpg' },
];

const ImageGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className='container gallery-section m-auto'>
      <h2 className='text-center pb-3 gallery-head'>Our Products</h2>
      <div className="image-gallery">
        {images.map((image) => (
          <img
            key={image.id}
            src={image.src}
            alt={image.alt}
            onClick={() => openModal(image.src)}
            className="gallery"
          />
        ))}
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="Selected" />
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
