import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function WaterParkEquipmentManufactureInIndia(){
    const imgData = [
        {
            img1:'Product-Images/Water-park-equipment-manufactures-in-pune.jpg',
            name:'Water Park Equipment',
            price:'Rs 25 Lakh  / SetGet Best Price',
            d1:'Material',
            value1:'FRP',
            d2:'Color',
            value2:'All Color',
            d3:'Exit',
            value3:'Pool',
            d4:'Usage/Application',
            value4:'water Park',
            d5:'Age Group',
            value5:'Children & Adults',
            d6:'Is It Customized',
            value6:'Customized',
            d7:'Size',
            value7:'34*18*18Ft',
            d8:'Number Of Slides',
            value8:'5',
            para:'Nageswar Fiber & Fabrication Industries is Listed amongst the reckoned manufactures of aqualitative range of products like Garden dustbins,Portable Cabinsamd much more.our whole product Assortment is designed and manafactured by the experienced proffessionals using supreme quality raw material and advanced methodology in order to ensure superiorityin Quality.'
        },
        {
            img1:'Product-Images/Amusement-park-equipment-manufactures-in-india.jpg',
            name:'Amusement park Water Slide',
            price:'Rs 22 Lakh  / Piece',
            d1:'Minimum Order Quantityl',
            value1:'100 Piece',
            d2:'Material',
            value2:'FRP',
            d3:'Exit',
            value3:'Pool',
            d4:'Color',
            value4:'Yellow',
            d5:'Is It Customized',
            value5:'Customized',
            d6:'Usage/Application',
            value6:'Use at Water Park',
            d7:'Rider Capacity',
            value7:'3',
            d8:'Funnel Diameter	',
            value8:'20 Feet',
            d9:'No Of Riders',
            value9:'5',
            d10:'Bucket Splashing Water Capacity',
            value10:'15-25Ltr',
            d11:'Number Of Slides',
            value11:'4',
            d12:'Standard',
            value12:'ASTM',
            d13:'Capacity',
            value13:'30 People',
            d14:' I Deal In',
            value14:'New Only',
            d15:'Lighting',
            value15:'Yes',
            d16:'Corrosion Resistance',
            value16:'Yes',
            para:'Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.'
        },
        {
            img1:'Product-Images/Water-park-manufactures-company-inpune.jpg',
            name:'Water Park Equipment',
            price:'Rs 1.5 Lakh  / Sets',
            d1:'Minimum Order Quantity',
            value1:'1 Set',
            d2:'Material',
            value2:'FRP',
            d3:'Color',
            value3:'Multicolour',
            d4:'Exit',
            value4:'Pool',
            d5:'Usage/Application',
            value5:'Water Park, Resort',
            d6:'Rider Capacity',
            value6:'15-20',
            d7:'Age Group',
            value7:'3 To Above',
            d8:'Is It Customized',
            value8:'Customized',
            d9:'No Of Riders',
            value9:'2',
            d10:'Number Of Slides',
            value10:'2',
            d11:'Standard',
            value11:'FRP, ASTM',
            d12:'Capacity',
            value12:'5-10',
            d13:'Max Height',
            value13:'8 Ft',
            d14:'Weather Resistance',
            value14:'Yes',
             para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
        },
        {
            img1:'Product-Images/Park-slides-manufactures-in-pune.jpg',
            name:'Frp Water Park Spiral And Curve Slides',
            price:'Rs 55 Lakh  / Set',
            d1:'Material',
            value1:'FRP',
            d2:'Minimum Order Quantity',
            value2:'1 set',
            d3:'Number Of Slides',
            value3:'6 Slides',
            d4:'Rider Capacity',
            value4:'120-240 Person',
            d5:'Exit',
            value5:'Water park',
            d6:'Color',
            value6:'Multicolor',
            d7:'No Of Riders',
            value7:'6 Lane',
            d8:'Usage/Application',
            value8:'Water Park',
            d9:'Pipe Thickness',
            value9:'GI PIPE',
            d10:'Country of Origin',
            value10:'Made in India',
            para:'Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.'
        },
      
      {
        img1:'Product-Images/Family-rides-at-Thorpe-park-manufactures-in-india.jpg',
        name:'Open Body Slide,Close Body Slide,Multilane Slide,Family Slide',
        price:'Rs 4,500  / sq ft ',
        d1:'Material',
        value1:'FRP(fibre reenforced plastic)',
        d2:'Exit',
        value2:'Pool',
        d3:'Color',
        value3:'Red,Yellow,Blue',
        d4:'Rider Capacity',
        value4:'2 One Time',
        d5:'Number Of Slides',
        value5:'4',
        d6:'No Of Riders',
        value6:'Custom',
        d7:'Usage/Application',
        value7:'Entertainment',
        d8:'Country of Origin',
        value8:'Made in India',
        para:'Nageswar Fiber and Fabrication These Slides are must for all Water Park,Hotels,and Resort.These are manufactured As per the International Standards usibng  high-grade basic materials that are procured from the reliable vendors. These Water slide are offered in a varity of designs, shapes,sizes, and colours.'
    },
   
  {
    img1:'Product-Images/Pendulum water Slide 1.jpg',
    name:'Pendulum Water Slide',
    price:'Rs 9 Lakh  / Set',
    d1:'Material',
    value1:'FRP(fibre reenforced plastic)',
    d2:'Minimum Order Quantity',
    value2:'1 set',
    d3:'Color',
    value3:'Colourful',
    d4:'Exit',
    value4:'Pool',
    d5:'Rider Capacity',
    value5:'100/500kg',
    d6:'No Of Riders',
    value6:'1',
    d7:'Usage/Application',
    value7:"Water park",
    para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
},


 ]
    
    return(
        <>
          <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191,
          +91 9762431883| Nageshwar Fibre And Fabrication Industries| Fiber Bathroom Door Manufacturers in Pune
          | Fibre School Furniture Manufacturers in Pune | Garbage Truck Manufacturers in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Bathroom Door Manufacturers in Pune
          | Fibre School Furniture Manufacturers in Pune | Garbage Truck Manufacturers in Pune
        "
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

       <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Water Park Equipment </h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Water Park Equipment Manufacturers in India</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
         {/*   ================= Start the Product ===================== */}
         <div className="mysection1">

         <div className="container product-section">
          <h1>
          Water Park Equipment Manufacturers in India – Nageshwar Fibre And
            Fabrication Industries
          </h1>
        <p>Nageshwar Fibre And Fabrication Industries is a leading manufacturer of water park equipment in India, specializing in designing and fabricating high-quality, safe, and fun water rides and attractions for water parks across the country. With a focus on innovation, safety, and durability, we offer a wide range of water park equipment to suit the needs of small, medium, and large water parks, resorts, and recreational facilities.</p>
        </div>   
        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                        <tr>
                          <th>{e.d11}</th>
                          <td>{e.value11}</td>
                        </tr>
                        <tr>
                          <th>{e.d12}</th>
                          <td>{e.value12}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact"></Link>Buy Now</button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


      {/*  ================= Contact Details ==================== */}
      <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        </>
    )
}