import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";


export default function FabricationWork(){

    const imgData = [
        {
            "img1": "Product-Images/Fiber Kolombus Ride.jpg",
            "name": "Fiber Kolombus Ride",
            "price": "Rs 9 Lakh / Piece",
            "d1": "Size/Dimension",
            "value1": "25 ft x 10 ft x 25 ft",
            "d2": "Seating Capacity",
            "value2": "60 seater",
            "d3": "Location",
            "value3": "Pune",
            "d4": "Color",
            "value4": "All colors",
            "d5": "Facilities",
            "value5": "Outdoor",
            "d6": "Usage/Application",
            "value6": "Waterpark equipment",
            "d7": "Type",
            "value7": "Outdoor Park",
            "para": "Nageshwar Fibre & Fabrication Industries proudly presents the **Fiber Kolombus Ride**, an exhilarating addition to any outdoor park or waterpark. With a length of 25 feet, a width of 10 feet, and a height of 25 feet, this ride is designed to accommodate up to 60 passengers, providing an exciting experience for large groups. Crafted from high-quality FRP material, the Kolombus Ride guarantees durability and safety, making it ideal for outdoor use. Available in a variety of vibrant colors, this ride enhances the aesthetic appeal of your amusement setup. Designed by experienced professionals using advanced manufacturing techniques, the Fiber Kolombus Ride offers both fun and functionality, ensuring a memorable experience for all thrill-seekers."
        },
        {
            "img1": "Product-Images/Industrial Shed Fabrication.jpg",
            "name": "Industrial Shed Fabrication",
            "price": "Rs 60 / sq ft",
            "d1": "Minimum Order Quantity",
            "value1": "20 sq ft",
            "d2": "Built Type",
            "value2": "Modular",
            "d3": "Usage/Application",
            "value3": "All",
            "d4": "Material",
            "value4": "Steel",
            "d5": "Height",
            "value5": "20-40 Feet",
            "d6": "Brand",
            "value6": "Nageshwar Fibre",
            "d7": "Shed Type",
            "value7": "Single Slope",
            "para": "Nageshwar Fibre & Fabrication Industries specializes in **Industrial Shed Fabrication**, offering a high-quality range of modular sheds tailored to meet various industrial needs. Our sheds are crafted from durable steel, designed for heights ranging from 20 to 40 feet, ensuring robust support and longevity. Each fabricated shed boasts a unique design that adheres to strict industrial standards, promising simplicity in installation and high durability. With competitive pricing and a commitment to quality, our industrial sheds provide a reliable solution for all your storage and workspace requirements. Trust Nageshwar Fibre for robust and efficient industrial fabrication solutions."
        },
        
        {
            "img1": "Product-Images/Vehicle Body Fabrication Works.jpg",
            "name": "Vehicle Body Fabrication Works",
            "price": "Rs 25,000 / Piece",
            "d1": "Features",
            "value1": "Cost Effective",
            "d2": "Material Procurement",
            "value2": "Our End",
            "d3": "Payment Mode",
            "value3": "Offline",
            "d4": "Service Type",
            "value4": "Vehicle Body Fabrication Works",
            "d5": "Product Type",
            "value5": "MS Material",
            "d6": "Service Location",
            "value6": "Pune",
            "para": "At Nageshwar Fibre & Fabrication Industries, we specialize in **Vehicle Body Fabrication Works**, offering high-quality services tailored to meet your specific needs. Priced at **Rs 25,000 per piece**, our services are designed to be cost-effective without compromising on quality. We handle the material procurement, ensuring that all components are sourced from reliable suppliers. Payments can be made offline for your convenience. Our expert team in Pune utilizes advanced fabrication techniques to deliver superior vehicle bodies, meeting stringent industry standards. Choose us for durable, efficient, and customized vehicle body solutions that enhance your vehicle's performance and appearance."
        }
        
            
    ]
    return(
        <>
         <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries|Fiber Stage manufacturer in Pune |
          Rain Dance Setup Manufacturers in Pune |
          Playground Equipment Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Stage manufacturer in Pune ,Rain Dance Setup Manufacturers in Pune ,Playground Equipment Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

<section
        className="breadcrumb-header"
        id="page"
        style={{ backgroundImage: `url(images/header/02_header.jpg)` }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Fabrication Work </h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Fabrication Companies in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      
     {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        <div className="container product-section">
          <h1>
          Fabrication Companies in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
        <p>Nageshwar Fibre & Fabrication Industries is a leading name in the fabrication industry, located in Pune, India. We specialize in providing a diverse range of high-quality fabrication services tailored to meet the needs of various sectors. Our commitment to excellence and customer satisfaction sets us apart as one of the premier fabrication companies in the region.</p>
        </div>
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>


        {/*  ================= Contact Details ==================== */}
        <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div> 
        </>
    )
}