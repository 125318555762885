import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";



export default function AnimalFiberStatueInPune(){
    const imgData = [
        {
          img1: "Product-Images/Elephant-statue-manufactures-in-pune.jpg",
          name: "FRP Elephant Statue",
          price: "Rs 2 Lakh / Piece",
          d1: "Brand",
          value1: "Nff brand",
          d2: "Design",
          value2: "Fiber Elephant",
          d3: "Size",
          value3: "Height 8 ft",
          d4: "Type",
          value4: "Fiber Statue",
          d5: "Color",
          value5: "All Colors",
          d6: "Material",
          value6: "Fiber",
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality. Fiber elephant statue heavy fiber material used with original effect",
        },
        {
          img1: "Product-Images/FRP Cow Statue.jpg",
          name: "FRP Cow Statue",
          price: " Rs 60,000  / Piece Get Best Price",
          d1: "Height 4.5 length 6 ft",
          value1: "30kg",
          d2: "Color",
          value2: "All colors",
          d3: "Size",
          value3: "Height 8 ft",
          d4: "Effect",
          value4: "Frp material use sun and rain protection",
          d5: "Shape",
          value5: "Original cow",
          d6: "Design",
          value6: "Original cow design",
          d7: "Usage/Application",
          value7: "Exterior Decor",
          d8: "Size/Dimension",
          value8: "Height4.5ft lenght 6 ft",
    
          para: "Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality",
          para1:
            "Frp cow statue heavy frp material used well finish with original effect",
        },
        {
          img1: "Product-Images/Decorative Peacock statue.jpg",
          name: "Decorative Peacock",
          price: " Rs 12,000  / Piece",
          d1: " Surface",
          value1: "Finish	Colored",
          d2: "Country of Origin",
          value2: "Made in India",
          d3: " Color",
          value3: "Multicolor",
          d4: "Design",
          value4: "Peacock",
          d5: "Usage/Application",
          value5: "Decoration",
          d6: "Brand",
          value6: "Nageshwar fibre",
    
          para: " Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
          para1: "Frp decorative materials",
        },
        {
          img1: "Product-Images/Fiber Horse Statue.jpg",
          name: "Fiber Horse Statue",
          price: "Rs 50,000  / Piece Get Best Price",
          d1: "Minimum Order Quantity",
          value1: "1 Piece",
          d2: " Height 5 ft",
          value2: "5 feet",
          d3: "Country of Origin",
          value3: "Made in India",
          d4: "Usage/Application",
          value4: "Exterior Decor",
          d5: "Material",
          value5: "Fiber",
          d6: "Color",
          value6: "Golden",
          d7: "Size/Dimension",
          value7: "Weight-60 kgs",
    
          para: "  Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
          para1: "Hevy fiber material 5 mm thikness",
        },
    
        {
          img1: "Product-Images/FRP Bullock Cart.jpg",
          name: "FRP Bullock Cart",
          price: "Rs 25,000  / Piece Get Best Price",
          d1: "Minimum Order Quantity",
          value1: "1 Piece",
          d2: "Country of Origin",
          value2: "Made in India",
          d3: "Work",
          value3: "Heavy Fiber and fabrication work",
          d4: "Usage/Application",
          value4: "Field",
          d5: "Size",
          value5: "Height-1 feet Length-4 Feet",
          d6: "Design",
          value6: "Antique",
          d7: "Color",
          value7: "Brown",
          para: " Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.",
          para1: "Heavy fiber and fabrication structure",
        },

        {
            img1: 'Product-Images/Shivaji-maharaj-statue-maker-in-pune.jpg',
            name: 'Shivaji Maharaj Statue',
            price: 'Rs 1.01 Lakh / Piece Get Best Price',
            d1: 'Material',
            value1: 'Polyresin',
            d2: 'Height',
            value2: '2-5 Feet',
            d3: 'Usage/Application',
            value3: 'Any',
            d4: 'Color',
            value4: 'Any',
            d5: 'Statue Type',
            value5: 'Fiber',
            d6: 'Size/Dimension',
            value6: '2 Feet',
            d7: 'Is It Handmade',
            value7: 'Handmade',
            d8: 'Is It Polished',
            value8: 'Polished',
            para: 'The Shivaji Maharaj Statue is a stunning representation of the great Maratha king, crafted from high-quality polyresin. Standing at 2 feet, this handmade and polished statue is perfect for any setting, symbolizing valor and heritage. Ideal for home decor or as a tribute, this statue reflects intricate details and artistry, making it a valuable addition to any collection.'
        },
        {
            img1: 'Product-Images/Fiberglass Gauri Mahalakshmi Statue.jpg',
            name: 'Fiberglass Gauri Mahalakshmi Statue',
            price: 'Rs 10,000 / Set Get Best Price',
            d1: 'Height',
            value1: '4 Feet',
            d2: 'Size/Dimension',
            value2: '4 Ft',
            d3: 'Material',
            value3: 'Fiberglass',
            d4: 'Brand',
            value4: 'NFF INDUSTRIES',
            d5: 'Country of Origin',
            value5: 'Made in India',
            para: 'The Fiberglass Gauri Mahalakshmi Statue stands at 4 feet, beautifully crafted from high-quality fiberglass. This statue is not only a symbol of prosperity and abundance but also showcases exceptional craftsmanship and intricate details. With a minimum order quantity of 2 sets, this statue is ideal for decorative purposes in homes, temples, or cultural events, adding a divine touch to any space.'
        }
      
        
      ];
     
    return(
        <>
          <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
         Contact: +91 8793191191, +91 9762431883| Nageshwar Fibre And Fabrication Industries|Fiber Stage manufacturer in Pune |
          Rain Dance Setup Manufacturers in Pune |
          Playground Equipment Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Stage manufacturer in Pune ,Rain Dance Setup Manufacturers in Pune ,Playground Equipment Manufacturers in India"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
       <meta property="og:image" content="https://www.nageshwarfiber.com/ogimage.jpg" />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>


<section
        className="breadcrumb-header gallery-image1"
        id="page"
       
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1> Animal and Fiber Statue </h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li> Animal and Fiber Statue Manufacturers in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
           
           {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}


        <div className="container product-section">
          <h1>
          Animal and Fiber Statue Manufacturers in Pune – Nageshwar Fibre And
            Fabrication Industries
          </h1>
          <p>Nageshwar Fibre and Fabrication Industries in Pune is a prominent name in the manufacturing of animal and fiber statues. Renowned for their artistic craftsmanship and attention to detail, Nageshwar Fibre specializes in creating high-quality statues that are both durable and visually stunning. Whether it’s life-sized animal sculptures or intricate fiber statues for decorative and commercial purposes, the company brings a combination of creativity, technology, and skilled fabrication to each project.</p>
        </div>
  
        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>

       {/*  ================= Contact Details ==================== */}
       <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>
        
        </>


    )
}