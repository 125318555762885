import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./CSS/responsive.css";
import "./CSS/nice-select.css";
import "./CSS/owl.carousel.min.css";
import "./CSS/owl.theme.default.min.css";
import "./Header.css";
import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage navbar collapse

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false); // Close the navbar when a link is clicked
  };
  return (
    <>
      <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Nageshwar Fibre And Fabrication Industries| Contact: +91 8793191191,
          +91 9762431883|Tube Slide Water Park Contractor in Pune | Fiber Pillar
          Manufacturers in Pune | Water-Fountain-Manufacturers-in-Pune | frp
          Dome Manufacturers in India
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Pillar Manufacturers in Pune,Water-Fountain-Manufacturers-in-Pune,frp Dome Manufacturers in India,Plastic dustbin manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/images/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <header className="myheader">
        <div className="nav-bar1 container ">
          <div>
            <a href="tel:+919762431883">
              {" "}
              <i class="bi bi-telephone-fill"></i> +91 8793191191 / +91
              9762431883{" "}
            </a>
          </div>
          <div className="email">
            <a href="mailto:info@nageshwarfiber.com">
              <i class="bi bi-envelope"></i> info@nageshwarfiber.com
            </a>
          </div>
        </div>
        {/* ============================= */}

<nav className="navbar navbar-expand-lg mynav1">
      <div className="container">
        <div className="logo navbar-brand div1">
          <Link to="/" className="logo-nav">
            <img src="./logo.jpg" alt="logo" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleToggle} // Toggle navbar on button click
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse div2 ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link active" aria-current="page" onClick={handleClose}>
                Home
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/about"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
               
              >
                About
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/about" className="dropdown-item" onClick={handleClose}>
                    About us
                  </Link>
                </li>
                <li>
                  <Link to="/about-the-founder" className="dropdown-item" onClick={handleClose}>
                    About the Founder
                  </Link>
                </li>
                <li>
                  <Link to="/policy" className="dropdown-item" onClick={handleClose}>
                    Privacy & Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="dropdown-item" onClick={handleClose}>
                    Terms & Condition
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/products"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
               
              >
                Products
              </Link>
              <ul className="dropdown-menu mydropdownmenu">
              <li className="has-menu">
                  <Link to="/products" className="dropdown-item" onClick={handleClose}>
                    Products
                  </Link>
                </li>
                <li className="has-menu">
                  <Link to="/animal-statue-manufacturer-in-pune" className="dropdown-item" onClick={handleClose}>
                    Animal Statue
                  </Link>
                </li>
                <li>
                      <Link
                        to="/play-equipment-manufacturer-in-pune"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Playground Slides
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/water-park-slide-manufacturers"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Water Park Slides
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/water-slide-manufacturers-in-india"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Water Slides
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/park-slide-manufacturers-in-india"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Park Slides
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/wedding-baggi-manufacturers-in-india"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Wedding Baggi
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/swimming-pool-manufacturers-in-india"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        FRP Swimmimg Pool
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/paddle-boat-manufacturers-in-india"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        FRP Paddle Boat
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/wedding-fiber-stage-manufacturer-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Fiber Stages
                      </Link>
                    </li>
                    <li>
                      <Link to="/rain-dance-setup" class="dropdown-item"  onClick={handleClose}>
                        Rain Dance Setup
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/playground-equipment-manufacturers"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Playground Equipment
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/benches-manufacturers-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Benches
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/portable-cabin-manufacturers-in-pune"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Cabins
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/domes-manufacturer-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Domes
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dustbin-manufacturer-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Dustbin
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/garden-umbrella-manufacturers-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Garden Umbrella
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/comercial-dining-table-manufacturers-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Commercial Dining Table
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fiber-toilets-manufacturers-in-pune"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Fiber Toilet
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/garden-fountain-manufacturer-in-india"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Garden Fountain
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fiberglass-swimming-pool-manufacturers-in-india"
                        class="dropdown-item"    onClick={handleClose}
                      >
                        Fiberglass Swimming Pool
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/door-manufacturers-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Door
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/merry-go-round-manufacturer-in-pune"
                        class="dropdown-item"  onClick={handleClose}
                      >
                        Swing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/frp-pot-manufacture-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Frp Flower Pot
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fiber-statue-manufacturers-in-pune"
                        class="dropdown-item"   onClick={handleClose}
                      >
                        Fiber Statue
                      </Link>
                    </li>
                    <li>
                      <Link to="/other-product" class="dropdown-item"  onClick={handleClose}>
                        Other Product     
                      </Link>
                    </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link to="/gallery-images" className="nav-link active" onClick={handleClose}>
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/innovation" className="nav-link active" onClick={handleClose}>
                Innovation at Core
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-link active" onClick={handleClose}>
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/project-industry" className="nav-link active" onClick={handleClose}>
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/blogs" className="nav-link active" onClick={handleClose}>
                Blogs
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/enquiry" className="nav-link active" onClick={handleClose}>
                Enquiry
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link active" onClick={handleClose}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
      </header>
    </>
  );
};

export default Header;
