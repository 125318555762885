import { Link } from "react-router-dom";
import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

export default function AboutFounder() {

    const projects = [
        {
          name: '2-Seater Swan paddle boat',
          image: "gallery/2-seater-boat.jpg",
        },
        {
          name: 'Decorative Stage',
          image: 'gallery/wedding stage manufacture in pune.jpg',
        },
        {
          name: 'Hemisphere Dome',
          image: 'gallery/FRP Domes.jpg',
        },
        {
          name: 'Garden Fountain',
          image: 'gallery/fountain.jpg',
        },
        {
          name: 'Fibre Pillar',
          image: 'gallery/FRP pillars.jpg',
        },
        {
          name:'Kolambus Ride',
          image:'gallery/kolambus ride.jpg'
        }
      ];


      const project1 = [
        {
          name: 'Playground Equipment',
          image: "gallery/playground equipment.jpg",
        },
        {
          name: 'Fibre umbrella',
          image: 'gallery/Fibre umbrella.jpg',
        },
        {
          name: 'Food Van',
          image: 'gallery/food van.jpg',
        },
        {
          name: 'Tube Slide Water Park',
          image: 'gallery/tube slider.jpg',
        },
        {
          name: 'Wedding horse Rath',
          image: 'gallery/wedding-horse-rath.jpg',
        },
      ];


      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
  return (
    <>
    <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",

              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.8",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191,+91 9762431883| Nageshwar Fibre And Fabrication Industries| Playground Slide Manufacturers in Pune | Water Park
          Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Playground Slide Manufacturers in Pune, Water Park Slide Manufacturers in India ,Park Slides Manufacturer in Pune"
        />
        <meta
          name="keywords"
          content="Playground Slide Manufacturers in Pune, Water Park Slide Manufacturers in India , Park Slides Manufacturer in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fibre and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section
        className="breadcrumb-header breadcrumb-header-2 gallery-image"
        id="page"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Vision & Mission</h1>
                <ul>
                  {/* Use Link component if routing is set up */}
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>About the Founder</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ================================ */}
      <section className="about-us about-us-home-2 about-page-2 founder-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 founder-img">
              <img
                className=""
                src="Product-Images/Fabrication-company-in-pune.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="">
                <h1 className="text-center text-orange">About the founder</h1>
                <p>
                  Mr. Nikhil Wavhal is the visionary founder of Nageshwar Fibre
                  & Fabrication Industries, a company dedicated to delivering
                  high-quality fibre and fabrication solutions. With a robust
                  background in engineering and manufacturing, Nikhil has a deep
                  understanding of the industry, which he has leveraged to build
                  a company that stands out for its innovation and commitment to
                  excellence.
                </p>

                <p>
                  His journey began with a passion for engineering and a desire
                  to create sustainable and efficient products, leading him to
                  identify a gap in the market for high-quality fibre solutions
                  and establish Nageshwar Fibre & Fabrication Industries. Under
                  his leadership, the company has grown rapidly, expanding its
                  product offerings and establishing a reputation for
                  reliability and customer satisfaction.
                </p>

              </div>
              
            </div>
          </div>
          <p>
                  Nikhil's approach to leadership emphasizes teamwork,
                  integrity, and continuous improvement, believing in empowering
                  his team, fostering a culture of collaboration, and
                  encouraging innovative thinking. This philosophy has not only
                  contributed to the company's success but has also made
                  Nageshwar Fibre & Fabrication Industries a preferred partner
                  in the industry.
                </p>

                <p>
                  Committed to sustainability, Nikhil strives to implement
                  eco-friendly practices within the company's operations, with a
                  vision for expanding the company's reach and developing
                  products that meet evolving customer needs while minimizing
                  environmental impact.
                </p>

                <p>
                  In addition to his work, Nikhil actively participates in
                  industry forums and community initiatives, sharing his
                  knowledge and experience to inspire the next generation of
                  entrepreneurs and engineers.
                </p>
        </div>
      </section>

       {/* <!-- ============= Our vision and mission section =================== --> */}
       <section class="services" id='vision-section'>
        <div class="container">
          <h2 className="text-center fs-1 text-brown pb-2">Our Vision & Mission</h2>
          <div class="row">
            <div class="col-12  d-flex vision-box p-2">
              <div className="col-6  vision-b1">
                <h2 className="text-center p-2 fs-1 text-orange">Vision</h2>
                <p>
                  At Nageshwar Fibre & Fabrication Industries, our vision is to
                  be a global leader in innovative fibre and fabrication
                  solutions, setting new standards for quality, sustainability,
                  and customer satisfaction. We aim to pioneer advancements that
                  seamlessly blend technology with creativity, transforming
                  industries and enriching lives through our durable,
                  eco-friendly, and cutting-edge products.
                </p>
              </div>
              <div class="col-6 border">
                <img src="products-img/Vision & Mission Section-02.jpg" alt="mission image" />
              </div>
            </div>
            {/* ================ mission section =================== */}
            <div class="col-12  value-box p-2">
             
                <h2 className="text-center text-orange fs-1">Our Values</h2>
                <p>
                  At Nageshwar Fibre & Fabrication Industries, our core values
                  guide everything we do. These principles form the foundation
                  of our commitment to excellence, innovation, and
                  sustainability.
                </p>
                <ul>
                  <li>
                    <strong>Integrity:</strong> We operate with honesty,
                    transparency, and accountability, ensuring that every
                    interaction and decision reflects our ethical standards.
                  </li>
                  <li>
                    <strong>Quality:</strong> Our unwavering dedication to
                    producing high-quality fibre and fabrication products is at
                    the heart of our work. We strive for excellence through
                    rigorous testing and continuous improvement in all our
                    processes.
                  </li>
                  <li>
                    <strong>Innovation:</strong> We embrace creativity and
                    forward-thinking, always seeking to push the boundaries of
                    what’s possible in fibre and fabrication technology.
                    Innovation drives our solutions and helps us stay ahead in
                    the industry.
                  </li>
                  <li>
                    <strong>Sustainability:</strong> We believe in creating
                    products that not only meet customer needs but also
                    contribute to a greener, more sustainable future.
                    Eco-friendly practices and reducing our environmental
                    footprint are central to our mission.
                  </li>
                  <li>
                    <strong>Customer Focus:</strong> Understanding and exceeding
                    our customers’ expectations is a priority. We build
                    long-term partnerships by delivering tailored solutions and
                    ensuring satisfaction in every project.
                  </li>
                  <li>
                    <strong>Teamwork:</strong> Collaboration and mutual respect
                    are key to our success. We foster a work environment where
                    each team member is valued, encouraging creativity,
                    knowledge sharing, and growth.
                  </li>
                </ul>
          
            </div>
            <div class="col-12  d-flex mission-box p-2 vision-box">
              <div class="col-6">
                <h2 className="text-center fs-1 text-orange">Mission</h2>
                <p>
                  Our mission is to deliver superior fibre and fabrication
                  products that meet the highest standards of craftsmanship and
                  innovation. We are committed to:
                </p>
                <ul>
                  <li>
                    <strong>Excellence in Quality:</strong> Providing
                    exceptional products that exceed customer expectations
                    through rigorous quality control and continuous improvement.
                  </li>
                  <li>
                    <strong>Sustainable Solutions:</strong> Leading the way in
                    eco-friendly and sustainable manufacturing practices,
                    contributing to a greener future.
                  </li>
                  <li>
                    <strong>Customer-Centric Approach:</strong> Understanding
                    and anticipating the evolving needs of our clients to
                    deliver customized solutions that add value to their
                    business.
                  </li>
                  <li>
                    <strong>Innovation and Growth:</strong> Investing in
                    cutting-edge technology and research to remain at the
                    forefront of industry developments while fostering a culture
                    of innovation and creativity.
                  </li>
                </ul>
              </div>
             
              <div className="border col-6">
                <img src="products-img/Vision & Mission Section-01.jpg" alt="vison image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======================== */}

      {/* ==========================  products carousel ===================== */}
      <section className="about-project">

      <div className="project-carousel project-section">
      <h2 className='fs-1 text-brown'>Our Projects</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            </div> 
          </div>
        ))}
      </Slider>
    </div>

      </section>
      


    {/* ================ */}

    <div className="project-carousel">
      <h2 className="text-orange pb-2">Our Projects</h2>
      <Slider {...settings}>
        {project1.map((project, index) => (
          <div key={index} className="project-slide">
            <div className="project-image">
              <img src={project.image} alt={project.name} />
              <div className="project-name">{project.name}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    </>
  );
}
