import "./CSS/style.css";
import "./CSS/animate.css";
import "./CSS/bootstrap.min.css";
import "./CSS/lity.min.css";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Slider.css";
import { motion } from "framer-motion";
import "./ProductBased.css";
import { Helmet } from "react-helmet";

export default function ParkSlidesManufcaturesInPune(){
    const imgData = [
        {
            img1:'Product-Images/Water-park-slides-manufactures-in-pune.jpg',
            name:'Water Park Slide',
            price:'Rs 30 Lakh  / Piece',
            d1:'Material',
            value1:'FRP',
            d2:'Capacity',
            value2:'50 pery',
            d3:'Exit',
            value3:'Pool',
            d4:'Color',
            value4:'All Color',
            d5:'Rider Capacity',
            value5:'1 of each slide',
            d6:'Funnel Diameter',
            value6:'32 inch',
            para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
    },
    
    {
        img1:'Product-Images/Kolambus Ride.jpg',
        name:'FRP Kolambus RIDE',
        price:'Rs 11 Lakh  / Piece',
        d1:'Material',
        value1:'Mild Steel',
        d2:'Capacity',
        value2:'70 seater',
        d3:'Shape',
        value3:'Oval',
        d4:'Type',
        value4:'Amusement Park Rides',
        d5:'Color',
        value5:'All colours',
        d6:'Usage/Application',
        value6:'Waterpark',
        d7:'Size/Dimension',
        value7:'25 ft height',
        para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
    },
    {
        img1:'Product-Images/Water-slides-manufactures-in-india.jpg',
        name:'Water Park Slide',
        price:'Rs 3,500  / Piece',
        d1:'Material',
        value1:'FRP(fibre reenforced plastic)',
        d2:'Exit',
        value2:'Pool',
        d3:'Color',
        value3:'All Color',
        d4:'Usage/Application',
        value4:'Water Park',
        d5:'Corrosion Resistance',
        value5:'All colours',
        d6:'Usage/Application',
        value6:'Yes',
        d7:'Country of Origin',
        value7:'Made in India',
        para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
    },
    
    {
        img1:"Product-Images/MultiActiviity-play-station-manufactures-in-pune.jpg",
        name:'Kids Multiplay System',
        price:'Rs 90,000  / Piece',
        d1:'Minimum Order Quantity',
        value1:'1 Piece',
        d2:'Brand',
        value2:'Kidsplay',
        d3:'Color',
        value3:'All Color',
        d4:'Material',
        value4:'FRP',
        d5:'Child Age Group',
        value5:'5 year',
        d6:'Type',
        value6:'Kids Multiplay',
        d7:'Country of Origin',
        value7:'Made in India',
        para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
    },
    
    {
      img1:'Product-Images/Water-park-equipment-manufactures-in-pune.jpg',
      name:'Water Park Equipment',
      price:'Rs 25 Lakh  / Set',
      d1:'Minimum Order Quantity',
      value1:'1 Set',
      d2:'Material',
      value2:'FRP',
      d3:'Color',
      value3:'All Color',
      d4:'Exit',
      value4:'Pool',
      d5:'Usage/Application',
      value5:'Water Park',
      d6:'Design',
      value6:'As per standard',
      d7:'Age Group',
      value7:'Any Age Group',
      d8:'Is It Customized',
      value8:'Customized',
      d9:'Size',
      value9:'18*14*7M',
      d10:'Standard',
      value10:'ASTM',
      para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
    },
    
    {
      img1:'Product-Images/Water-park-equipment-manufactures-in-pune.jpg',
      name:'Water Park Equipment',
      price:'Rs 10 Lakh  / Set',
      d1:'Material',
      value1:'FRP',
      d2:'Color	All',
      value2:'Colour Available',
      d3:'Exit',
      value3:'Pool',
      d4:'Usage/Application',
      value4:'Swimming Pool',
      d5:'Design',
      value5:'As Per Rquierment',
      d6:'Age Group',
      value6:'Adults &Children',
      d7:'Is It Customized',
      value7:'Customized',
      d8:'Standard',
      value8:'ASTM',
      para:'Nageshwar Fibre & Fabrication Industries is listed amongst the reckoned manufacturers of a qualitative range of products like Garden Dustbin, Portable Cabins and much more. Our whole product assortment is designed and manufactured by the experienced professionals using supreme quality raw material and advanced methodology in order to ensure superiority in quality.'
    },
    
    {
      img1:'Product-Images/Fiberglass-swimming-pool-manufactures-near-me.jpg',
      name:'Glass Fiber Water Park Slides',
      price:'Rs 32 Lakh  / Piece',
      d1:'Material',
      value1:'Glass Fibre',
      d2:'Number Of Slides',
      value2:'1 Slide',
      d3:'Rider Capacity',
      value3:'500 kg',
      d4:'Exit',
      value4:'Pool',
      d5:'Color',
      value5:'All Colour Available',
      d6:'No Of Riders',
      value6:'1',
      d7:'Usage/Application',
      value7:'water Park',
      d8:'Country of Origin',
      value8:'Made in India',
      para:'We are a entity in the industry.acstively commited towards providing an optimum quality range of Swimmimg Pool Water Slides.'
    },
    

        
    ]
    return(
        <>
        <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
          Contact: +91 8793191191, +91 9762431883 | Nageshwar Fibre And Fabrication Industries|Playground Slide Manufacturers in Pune  |
          Water Park Slide Manufacturers in India | Park Slides Manufacturer in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Plastic dustbin manufacturers in Pune, Portable Cabin Manufacturers in Pune, Fiber Door Manufacturers in Pune,FRP Planters Manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
        {/* <!-- :: Breadcrumb Header --> */}
     <section
        className="breadcrumb-header gallery-image1"
        id="page"
        
      >

        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner">
                <h1>Park Slides</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>Park Slides Manufacturer in Pune</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

           {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
      <div className="container product-section">
              <h1>Park Slides Manufacturer in Pune – Nageshwar Fibre And Fabrication Industries</h1>
              <p>As one of the leading park slides manufacturers in Pune, Nageshwar Fibre And Fabrication Industries specializes in designing and producing high-quality, safe, and durable slides for parks, playgrounds, schools, and recreational areas. With a wide variety of slide options, we cater to both small and large spaces, ensuring that children have a fun and safe playtime experience.</p>
              </div>

        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>

        {/*  ================= Contact Details ==================== */}
        <div className="container">
           <div className="col-12 col-lg-6 contact-box p-3 mb-4">
              <h3>Nageshwar Fibre And Fabrication Industries Contact Number</h3>
              <p>If you're looking for the best Fiber and Fabrication Product, contact Nageshwar Fibre And Fabrication Industries today. </p>
              <p>
              <a href="" className="">Mobile No :</a>
              <a href="tel:+91 8793191191"> +91 8793191191 </a>
              <a href="tel:+91 9762431883"> +91 9762431883 </a>
              </p>
             
              <a href="mailto:info@nageshwarfiber.com">Email Id : info@nageshwarfiber.com</a>
           </div>
          
      </div>

        </>
    )
}