import React, { useState, useEffect } from "react";
import './Slider.css'
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
export default function Fibertoilet() {
  const slides = [
    "slider/Slider for product section-01.jpg",
    "slider/Slider for product section-02.jpg",
  ];
   const imgData = [
    {
        img1: 'Product-Images/Mobile Toilet Van.jpg', 
        name: 'Mobile Toilet Van',
        price: 'Rs 3.75 Lakh / Piece Get Best Price',
        d1: 'Material',
        value1: 'FRP',
        d2: 'Built Type',
        value2: 'Prefab',
        d3: 'Usage/Application',
        value3: 'Mobile Toilet Van',
        d4: 'Brand',
        value4: 'NFF',
        d5: 'Color',
        value5: 'All Colors',
        d6: 'Size',
        value6: '3*3, 18 Feet',
        d7: 'Tank Capacity',
        value7: 'Water tank - 1000 Litres, Wastage tank - 2000 Litres',
        d8: 'Shape',
        value8: 'As per requirement',
        d9: 'No. Of Compartments',
        value9: '10',
        d10: 'Container Length',
        value10: '18 Feet',
        d11: 'Wall Height',
        value11: '8 Feet',
        d12: 'Pipe Material',
        value12: 'Paras system',
        d13: 'Country of Origin',
        value13: 'Made in India',
        para: 'We are the leading Mobile Toilet Van manufacturer and supplier. Mobile Toilet Van is manufactured from the latest technology and high-grade raw material.'
    },
    {
        img1: 'Product-Images/FRP-MobileToilet-Manufactures-in-pune.jpg', 
        name: 'Fiber Portable Toilets',
        price: 'Rs 15,000 / Piece Get Best Price',
        d1: 'Minimum Order Quantity',
        value1: '10 Pieces',
        d2: 'Material',
        value2: 'FRP',
        d3: 'Built Type',
        value3: 'Modular',
        d4: 'Shape',
        value4: 'Rectangular',
        d5: 'Usage/Application',
        value5: 'Fibre Toilets',
        d6: 'Is It Portable',
        value6: 'Portable',
        d7: 'Country of Origin',
        value7: 'Made in India',
        para: 'Developed by the use of excellent quality basic materials and progressive techniques, the provided range of Fiber Toilets is delivered by us within the scheduled time frame.'
    },

    {
        img1: 'Product-Images/FRP-Portable-toilet-and-urinals-manufactures-in-pune.jpg', 
        name: 'FRP Portable Toilets',
        price: 'Rs 15,500 / Piece Get Best Price',
        d1: 'Material',
        value1: 'FRP',
        d2: 'Built Type',
        value2: 'Modular',
        d3: 'Shape',
        value3: 'Square',
        d4: 'Usage/Application',
        value4: 'Toilet Usage',
        d5: 'Brand',
        value5: 'NFF',
        d6: 'Color',
        value6: 'All Colors',
        d7: 'Size',
        value7: '3*3 Feet',
        d8: 'Tank Capacity',
        value8: 'No',
        d9: 'No. Of Compartments',
        value9: 'Single',
        d10: 'Features',
        value10: 'Outdoor Material, FRP, Brand Modcon, Built Type',
        d11: 'Capacity',
        value11: '1 Person',
        d12: 'Total Weight',
        value12: '50 kg',
        d13: 'Surface Treatment',
        value13: 'FRP',
        d14: 'Is It Customized',
        value14: 'Customisation',
        d15: 'Waste Water Tank',
        value15: 'Customized',
        d16: 'Is It Portable',
        value16: 'Portable',
        d17: 'I Deal In',
        value17: 'New Only',
        para: 'FRP Portable Toilets are designed for convenient and efficient toilet usage, featuring modular construction and a compact design suitable for various outdoor settings.'
    }
    

    
    
   ]
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to change slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Automatically move the slide
  useEffect(() => {
    const sliderInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(sliderInterval); // Clean up on component unmount
  }, [currentSlide, 3000]);
  return (
    <>
      <Helmet>  {/* Google Tag Manager gtag.js script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16792020155"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-16792020155');
          `}
        </script>

        {/* Google Ads conversion phone number tracking */}
        <script>
          {`
            gtag('config', 'AW-16792020155/t6c1CLL4u_AZELvJh8c-', { 
              'phone_conversion_number': '8793191191' 
            });
          `}
        </script>
        {/* Schema Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Nageshwar Fibre and Fabrication Industries",
            description:
              "At Nageshwar fibre and Fabrication Industries, our mission is to create durable, efficient, and customized fabrication solutions that exceed industry standards",
            url: "https://www.nageshwarfiber.com",
            logo: "https://www.nageshwarfiber.com/logo1.jpg",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+91 8793191191",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
              {
                "@type": "ContactPoint",
                telephone: "+91 9762431883",
                contactType: "Customer Service",
                areaServed: "IN",
                availableLanguage: "English",
              },
            ],
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: "5",
              worstRating: "1",
              ratingValue: "4.9",
              ratingCount: "1574",
            },

            email: "info@nageshwarfiber.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Nageshwar Fibre And Fabrication Industries Gat No. 493, Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune - 412105, Maharashtra, India",
              addressLocality:
                "Shikrapur, Near Pune Nagar Highway, Malthan Phata, Shirur, Pune",
              addressRegion: "Maharashtra",
              postalCode: "412105",
              addressCountry: "IN",
            },
            sameAs: [
              "https://www.nageshwarfiber.com/play-equipment-manufacturer-in-pune",
              "https://www.nageshwarfiber.com/Fiber-Stage-manufacturer-in-Pune",
              "https://www.nageshwarfiber.com/frp-Planters-Manufacturers-in-Pune",
            ],
          })}
        </script>
        <title>
         Contact: +91 8793191191,+91 9762431883|  Nageshwar Fibre And Fabrication Industries|Fiber Pillar Manufacturers in Pune |
          Water-Fountain-Manufacturers-in-Pune | frp Dome Manufacturers in India
          | Plastic dustbin manufacturers in Pune
        </title>
        <meta
          name="description"
          content="Nageshwar fibre and Fabrications, are one of the leading manufacturers of a wide range of superior quality Fiber Reinforced Plastic Products"
        />
        <meta
          name="keywords"
          content="Fiber Pillar Manufacturers in Pune,Water-Fountain-Manufacturers-in-Pune,frp Dome Manufacturers in India,Plastic dustbin manufacturers in Pune"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Nageshwar Fibre and Fabrication Industries"
        />
        <meta
          property="og:description"
          content="High-quality fiber and fabrication products for various applications."
        />
        <meta
          property="og:image"
          content="https://www.nageshwarfiber.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.nageshwarfiber.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {index === currentSlide && (
              <img src={slide} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </div>


      {/*   ================= Start the Product ===================== */}
      <div className="mysection1">
        {/* Image Section */}

        {imgData.map((e) => {
          return (
            <>
            <div className="mysection1">
              <div className="product-container">
                <motion.div
                  className="image-container"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    src={e.img1} className="productimg"// Replace with actual product image
                    alt="FRP Elephant Statue"
                  />
                </motion.div>

                {/* Product Info Section */}
                <motion.div
                  className="product-details"
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <h1>{e.name}</h1>
                  <p className="price">{e.price}</p>
                  <div className="details-table">
                    <table>
                      <tbody>
                        <tr>
                          <th>{e.d1}</th>
                          <td>{e.value1}</td>
                        </tr>
                        <tr>
                          <th>{e.d2}</th>
                          <td>{e.value2}</td>
                        </tr>
                        <tr>
                          <th>{e.d3}</th>
                          <td>{e.value3}</td>
                        </tr>
                        <tr>
                          <th>{e.d4}</th>
                          <td>{e.value4}</td>
                        </tr>
                        <tr>
                          <th>{e.d5}</th>
                          <td>{e.value5}</td>
                        </tr>
                        <tr>
                          <th>{e.d6}</th>
                          <td>{e.value6}</td>
                        </tr>
                        <tr>
                          <th>{e.d7}</th>
                          <td>{e.value7}</td>
                        </tr>
                        <tr>
                          <th>{e.d8}</th>
                          <td>{e.value8}</td>
                        </tr>
                        <tr>
                          <th>{e.d9}</th>
                          <td>{e.value9}</td>
                        </tr>
                        <tr>
                          <th>{e.d10}</th>
                          <td>{e.value10}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
              <div className="content-section">
                <div className="paragraph-section">
                  <p>{e.para}</p>
                  <p>{e.para1}</p>
                </div>
                <motion.div className="buttons" whileTap={{ scale: 0.9 }}>
                  <button className="quote-btn"><Link to="/contact">Buy Now</Link></button>
                </motion.div>
              </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
